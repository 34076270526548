import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css";
import { Link, useNavigate } from "react-router-dom";
import { ImInfo } from "react-icons/im";
import { Tooltip } from "reactstrap";
import { useTheme } from "../../contexts/ThemeContext";
import "./Home.css";

const exampleFileExtensions = [
  // { ext: "Bluetooth HeadSet", navlinks: "/models/" },
  // { ext: "Cell Phone", navlinks: "/models/CellPhone.gltf" },
  // { ext: "Energy Panel", navlinks: "/models/EnergyPanel.gltf" },
  // { ext: "Epic Cell Phone", navlinks: "/models/EpicCellPhone.gltf" },
  // { ext: "Led Light", navlinks: "/models/LEDLight.gltf" },
  // { ext: "Lighting-Case", navlinks: "/models/Lighting-Case.gltf" },
  // { ext: "Prism", navlinks: "/models/Prism.gltf" },
  // { ext: "Retro Light LED", navlinks: "/models/RetroLightLED.gltf" },
  // { ext: "Roccstar", navlinks: "/models/ROCCSTAR.gltf" },
  // { ext: "Router", navlinks: "/models/Router.gltf" },
  // { ext: "Sharpy-LED", navlinks: "/models/SharpyLED.gltf" },
  // { ext: "Tablet", navlinks: "/models/Tablet.gltf" },
  // { ext: "Tablet19", navlinks: "/models/Tablet19.gltf" },
  // { ext: "Chair", navlinks: "/models/Chair.glb" },

  // {
  //   ext: "Interior Design",
  //   navlinks: "/models/01.Oasis Market Floor Plan interior design .gltf",
  // },
  { ext: "AC Remote Unit", navlinks: "/models/AC Remote Unit.gltf" },
  {
    ext: "Room Assest",
    navlinks: "/models/room assets.gltf",
  },
  { ext: "Oil Bottle's drum", navlinks: "/models/All Oil Bottle's_drum.glb" },
  { ext: "Table", navlinks: "/models/Table.gltf" },
  { ext: "Shelby Car", navlinks: "/models/shelby Car With Naming.gltf" },
  { ext: "Star Tower AC", navlinks: "/models/Star Tower AC.gltf" },
  {
    ext: "Chair",
    navlinks: "/models/Chair.gltf",
  },  
];


const Inner = () => {
  const { theme } = useTheme();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    const demoExtension = "gltf";

    // Store file extension in local storage
    localStorage.setItem("fileExtension", demoExtension);

    // Store file extension in cookies
    Cookies.set("fileExtension", demoExtension, { expires: 5 / 1440 });
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile);
      const extension = selectedFile.name.split(".").pop().toLowerCase();

      // Update file extension in local storage
      localStorage.setItem("fileExtension", extension);

      // Update file extension in cookies
      Cookies.set("fileExtension", extension, { expires: 5 / 1440 });

      // Navigate to the 3D model viewer
      navigate(`/model/${encodeURIComponent(fileUrl)}`);
    }
  };
  return (
    <>
      <div className={`hero ${theme}`}>
        <div className="online-3d-viewer top-25">
          <div className="text-center fs-5 fs-sm-3 fw-medium p-1">
            Drag and drop 3D models here.
          </div>
          <div className="fs-5 p-2">or</div>
          <div className="d-flex align-items-center gap-3 p-2">
            <div
              className="btn btn-outline-danger "
              style={{ borderRadius: "4px" }}
              onClick={handleIconClick}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none", borderRadius: "4px" }}
                onChange={handleFileChange}
                accept=".gltf,.glb,.stl,.fbx,.obj"
              />
              <label htmlFor="file-input" className="pointer">
                <svg
                  className="me-2"
                  viewBox="0 0 20 17"
                  style={{ height: "24px", width: "24px" }}
                >
                  <path
                    d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                    fill="#e14954"
                  />
                </svg>

                <span className="fs-5 ">Choose file</span>
              </label>
            </div>
            <div>
              <ImInfo style={{ height: "18px", width: "18px" }} id="ImInfo" />
              <Tooltip
                placement="right"
                isOpen={tooltipOpen}
                target="ImInfo"
                toggle={toggle}
                style={{
                  backgroundColor: theme === "dark" ? "#2a2b2e" : "#efefef85",
                  color: theme === "dark" ? "#fffff" : "#000000",
                }}
              >
                <div className="roboto-medium fw-medium border-bottom py-2">
                  Supported 3D Files
                </div>
                <div className="row px-2">
                  <div className="col-6 roboto-regular p-1">.glb</div>
                  <div className="col-6 roboto-regular p-1">.gltf</div>
                  <div className="col-6 roboto-regular p-1">.stl</div>
                  {/* <div className="col-6 roboto-regular p-1">.obj</div> */}
                  {/* <div className="col-6 roboto-regular p-1">.fbx</div> */}
                </div>
                <div className="border-top roboto-medium fw-medium  pt-2">
                  Max Upload Size
                </div>
                <div className="col roboto-regular p-1">250 MB</div>
              </Tooltip>
            </div>
          </div>

          <div className="content-container d-none d-sm-block">
            <div className="example-files  pt-3">
              <div className="fs-6 mb-0 px-1 py-2 w-50 d-inline-block ">
                Check an example file:
              </div>
              <div className="file-buttons">
                {exampleFileExtensions.map((file, index) => (
                  <Link
                    key={index}
                    to={`/model/${encodeURIComponent(file.navlinks)}`}
                    className="file-button"
                  >
                    {file.ext}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inner;

import React, { useEffect, useState, useRef } from "react";
import { PiDotsNineBold } from "react-icons/pi";
import "./Header.css";
import { useTheme } from "../../../contexts/ThemeContext";
import { getCookie } from "../../../utils/utils";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export const fetchCloudSearchApi = async (route) => {
  let res = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "GET",
    cache: "no-store",
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};

const Apps = () => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { theme } = useTheme();

  const toggle = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleOldClick = (href, event) => {
    event.preventDefault();
    toast.info(
      <div>
        Very soon transforming to Silo 5.0,
        <br />
        in mean time enjoy Silo 4.2
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      }
    );
    setTimeout(() => {
      window.location.href = href;
    }, 2000);
  };

  const handleClick = (app, event) => {
    if (app.oldsite) {
      handleOldClick(app.href, event);
    } else {
      toggle();
    }
  };
  const [isApps, setIsApps] = useState([]);
  const [loading, setLoading] = useState(true);

  const appmenudropdown = async (ev) => {
    // console.log("cfgbhnkm");
    setOpen(!open);
    ev.preventDefault();
    const result = await fetchCloudSearchApi("api/v1/apps/get-apps");

    setIsApps([
      result?.data?.core_apps,
      result?.data?.social_apps,
      result?.data?.productivity_apps,
      result?.data?.exchange_apps,
    ]);
    setLoading(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`hero ${theme}`}>
      <div ref={dropdownRef} className={`dropdown ${open ? "show" : ""}`}>
        <div
          href="#dropdown"
          onClick={appmenudropdown}
          className={`rounded-circle p-2 align-items-center ${
            open
              ? theme === "dark"
                ? "activeApps2"
                : "activeApps"
              : theme === "dark"
              ? "appicons2"
              : "appicons"
          }`}
        >
          <span className=" rounded-circle">
            <PiDotsNineBold className="  fs-3" />
          </span>
        </div>

        <div
          className={`dropdown-menu p-0 apps-menu  rounded-2 overflow-auto shadow ${
            open ? "show" : ""
          }`}
        >
          <div
            className="dropcontain p-1"
            style={{
              backgroundColor: theme === "dark" ? "#161616" : "#FFFFFE",
              color: theme === "dark" ? "#8094ae" : "#5D5D5D  ",
            }}
          >
            {loading ? (
              <div className="row m-0" style={{ paddingTop: "18px" }}>
                <div className="col-4">
                  <div
                    className={`rounded-2  mb-2 ${
                      theme === "dark" ? "appsThumbnail1" : "appsThumbnail2"
                    }`}
                  ></div>
                </div>
                <div className="col-4">
                  <div
                    className={`rounded-2  mb-2 ${
                      theme === "dark" ? "appsThumbnail1" : "appsThumbnail2"
                    }`}
                  ></div>
                </div>
                <div className="col-4">
                  <div
                    className={`rounded-2  mb-2 ${
                      theme === "dark" ? "appsThumbnail1" : "appsThumbnail2"
                    }`}
                  ></div>
                </div>
                <div className="col-4">
                  <div
                    className={`rounded-2  mb-2 ${
                      theme === "dark" ? "appsThumbnail1" : "appsThumbnail2"
                    }`}
                  ></div>
                </div>
                <div className="col-4">
                  <div
                    className={`rounded-2  mb-2 ${
                      theme === "dark" ? "appsThumbnail1" : "appsThumbnail2"
                    }`}
                  ></div>
                </div>
                <div className="col-4">
                  <div
                    className={`rounded-2  mb-2 ${
                      theme === "dark" ? "appsThumbnail1" : "appsThumbnail2"
                    }`}
                  ></div>
                </div>
                <div className="col-4">
                  <div
                    className={`rounded-2  mb-2 ${
                      theme === "dark" ? "appsThumbnail1" : "appsThumbnail2"
                    }`}
                  ></div>
                </div>
                <div className="col-4">
                  <div
                    className={`rounded-2  mb-2 ${
                      theme === "dark" ? "appsThumbnail1" : "appsThumbnail2"
                    }`}
                  ></div>
                </div>
                <div className="col-4">
                  <div
                    className={`rounded-2  mb-2 ${
                      theme === "dark" ? "appsThumbnail1" : "appsThumbnail2"
                    }`}
                  ></div>
                </div>
              </div>
            ) : (
              isApps.map((main_app, main_index) => (
                <React.Fragment key={main_index}>
                  <h6 className="px-3 pt-2 fw-normal textMuted">
                    {main_app?.label}
                  </h6>
                  <div className="list-apps">
                    {main_app?.data.map((app, index) => (
                      <li
                        key={index}
                        className={`${
                          theme === "light" ? "ApppsHover1" : "ApppsHover2"
                        }`}
                      >
                        <Link
                          to={app?.project_link}
                          onClick={(event) => handleClick(app, event)}
                        >
                          <span className="list-apps-media">
                            <img
                              src={
                                theme === "dark"
                                  ? app?.image_link
                                  : app?.dark_image_link
                              }
                              alt={app?.name}
                              style={{ width: "36px" }}
                            />
                            {/* <img
                              src={app?.image_link}
                              alt={app?.name}
                              style={{ width: "36px" }}
                            /> */}
                          </span>
                          <div
                            className={`justify-content-center mt-2 ${
                              theme === "light" ? "listTitle1" : "listTitle2"
                            }`}
                            dangerouslySetInnerHTML={{ __html: app?.name }}
                          />
                        </Link>
                      </li>
                    ))}
                  </div>
                </React.Fragment>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apps;

import React, { useState, useRef, useEffect, useCallback } from "react";
import "./common.css";
import "./Footer.css";
import { FaDatabase } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import debounce from "lodash.debounce";
import Cookies from "js-cookie";
import {
  d_home_logo,
  d_plus_logo,
  Homelightlogo,
  qrcode_logo,
  silo_marketplace_logo,
  silogame,
  silotalk_logo,
  storage_logo,
  store_apps,
  streamdeck_logo,
  tv_logo,
} from "../../../assets/Images/applogos2";

import {
  Popover,
  PopoverBody,
  Progress,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Tooltip,
} from "reactstrap";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { useTheme } from "../../../contexts/ThemeContext";
import TalkComponent from "./TalkComponent.jsx";
import Skeleton from "react-loading-skeleton";
import useStorageUsage from "../../hooks/useStorageUsage.jsx";
import { fetchAPI, postRequestData } from "../../../utils/api_helper.jsx";
import { getCookie } from "../../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Footer = () => {
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (index) => {
    setTooltipOpen({
      ...tooltipOpen,
      [index]: !tooltipOpen[index],
    });
  };

  const { theme } = useTheme();
  const popoverRef = useRef();
  const popoverTargetRef = useRef();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [loadingApp, setLoadingApp] = useState(null);
  const togglePopover = () => setPopoverOpen(!popoverOpen);
  const authToken = getCookie("authToken");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { storageDetails, loading } = useStorageUsage();

  const [footerApps, setFooterApps] = useState([]);

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      popoverTargetRef.current &&
      !popoverTargetRef.current.contains(event.target) &&
      !event.target.closest(".footerlogosPop-container")
    ) {
      setPopoverOpen(false);
    }
  };

  useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleImageInFooter = async (image, key, value) => {
    setLoadingApp(key);
    // if (!authenticated) {
    //   toast.error("Please login first");
    //   setLoadingApp(null);
    //   return;
    // }
    const Data = new FormData();
    Data.append("columnValue", value);

    try {
      Data.append("action", "update");
      Data.append("column", "apps");
      Data.append("columnKey", key);
      const addApps = await fetchAPI(Data, "api/v1/public/frontend-settings");
      if (addApps.status) {
        //  Re-fetch the updated footer apps after the change
        debouncedFetchFooterApps();
        // setFooterApps((prevFooterApps) =>
        //   prevFooterApps.map((app) =>
        //     app.key === key ? { ...app, footer_visibility: value } : app
        //   )
        // );
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error updating footer app visibility:", error);
    } finally {
      setLoadingApp(null);
    }
  };

  // const toggleImageInFooter = async (image, key, value) => {
  //   setLoadingApp(key);
  //   const Data = new FormData();
  //   Data.append("columnValue", value);

  //   try {
  //     Data.append("action", "update");
  //     Data.append("column", "apps");
  //     Data.append("columnKey", key);
  //     const addApps = await fetchAPI(Data, "api/v1/public/frontend-settings");
  //     if (addApps.status) {
  //       // Re-fetch the updated footer apps after the change
  //       debouncedFetchFooterApps();
  //       //         prevFooterApps.map((app) =>
  //       //           app.key === key ? { ...app, footer_visibility: value } : app
  //       //         )
  //       //       );
  //     } else {
  //       console.log("error");
  //     }
  //   } catch (error) {
  //     console.error("Error updating footer app visibility:", error);
  //   } finally {
  //     setLoadingApp(null);
  //   }
  // };

  const debouncedFetchFooterApps = useCallback(
    debounce(async () => {
      try {
        // const Data = {
        //   action: "fetch",
        // };
        const data = new FormData();
        data.append("action", "fetch");
        const response = await postRequestData(
          data,
          "api/v1/public/frontend-settings"
        );
        if (response.status) {
          setFooterApps(
            response?.data?.frontend_settings?.apps.map((app) => ({
              ...app,
              imgSrc: `https://api.silocloud.io/${app.imgSrc}`, // Directly use the URL here
              link:
                app.title === "Talk"
                  ? `${app.link}/token-login/${authToken}`
                  : `${app.link}`,
            }))
          );
          // setFooterApps((prevFooterApps) =>
          //   prevFooterApps.map((app) => {
          //     let tempApp =
          //       response?.data?.frontend_settings?.apps[app.key - 1] || null;
          //     return tempApp
          //       ? { ...app, footer_visibility: tempApp.footer_visibility }
          //       : app;
          //   })
          // );
        }
      } catch (error) {
        console.error("Error fetching footer apps:", error);
      }
    }, 500), // Adjust debounce delay as needed
    [toggleImageInFooter]
  );

  useEffect(() => {
    debouncedFetchFooterApps();
  }, []);

  return (
    <>
      <section
        className="section-footer w-100 position-fixed bottom-0 start-0 z-3 py-0 py-md-2  align-content-center"
        style={{
          backgroundColor: theme === "dark" ? "#2a2b2e" : "#fff",
          color: theme === "dark" ? "#8094AE" : "#364a63",
        }}
      >
        <div className="container-fluid py-2 py-md-0">
          <div className="row justify-content-between">
            <div className="col-2 col-lg-3 ps-3 pe-0 d-flex">
              <div className="d-flex justify-content-center align-items-center ">
                <div
                  className="HomeIcon"
                  style={{
                    backgroundColor:
                      theme === "dark" ? "#4442499e" : "#eceef51c",
                  }}
                >
                  <Link
                    to="https://silocloud.io/"
                    className={`HomeIcon d-flex justify-content-center align-items-center  `}
                  >
                    <img
                      src={theme === "dark" ? d_home_logo : Homelightlogo}
                      alt="HomeIconfooter"
                      className="FooterHomeIcon"
                      style={{ height: "36px", width: "36px" }}
                    />
                  </Link>
                </div>

                <div
                  className=" d-none d-lg-block rounded-3 px-3 py-2 ms-1"
                  style={{
                    backgroundColor: theme === "dark" ? "#4442499e" : "#f7f7f7",
                    color: theme === "dark" ? "#8094AE" : "#364a63",
                  }}
                >
                  <Link to="https://storage.silocloud.io/">
                    <div
                      className="d-flex align-items-center mb-1"
                      style={{
                        color: theme === "dark" ? "#fff" : "#5c5c5c",
                      }}
                    >
                      <div className=" mb-1">
                        <FaDatabase
                          name="db-fill"
                          style={{ height: "24px", width: "24px" }}
                        />
                      </div>

                      {loading ? (
                        <Skeleton width={100} height={8} baseColor="#c5bfe0" />
                      ) : (
                        <p
                          className={`fs-9px mb-0 ms-1 opacity-75 ${
                            theme === "light" ? "textDark" : ""
                          }`}
                        >
                          Cloud Storage
                          {isLoggedIn ? (
                            <span className="mx-1 ">
                              {storageDetails.usedStorageText}
                              <span className="mx-1">/</span>
                              {storageDetails.planStorageText}
                            </span>
                          ) : (
                            <span> / Used</span>
                          )}
                        </p>
                      )}
                    </div>
                    <div className="">
                      {!isLoggedIn ? (
                        <Progress color="info" className="progress-storage" />
                      ) : (
                        <Progress
                          value={
                            storageDetails.percentageUsed === 0
                              ? storageDetails.percentageUsed + 1
                              : storageDetails.percentageUsed
                          }
                          color="info"
                          className="progress-storage"
                        />
                      )}
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-9 col-lg-5 my-auto p-0 ">
              <div className="d-flex justify-content-center align-items-center">
                {footerApps.map((image, index) => {
                  let tempfooterApp =
                    image.footer_visibility === "1" || !image.popOver;
                  return (
                    tempfooterApp && (
                      <div
                        id={`footer-logo-${index}`}
                        key={index}
                        className="text-center me-1 me-lg-2 pointer"
                        onClick={() => {
                          if (image.useNavigate) {
                            navigate("/apps");
                          } else {
                            window.location.href = image.link;
                          }
                        }}
                      >
                        {!image.imgSrc ? (
                          <Spinner size="sm" className="mt-1 spinner-md" />
                        ) : (
                          <div
                            className={`position-relative footer-apps ${
                              popoverOpen ? "footerlogosPop-container" : ""
                            }`}
                            style={{
                              backgroundColor:
                                theme === "dark" ? "#4442499e" : "#f7f7f7",
                            }}
                          >
                            <div className="  ">
                              <div>
                                {" "}
                                <img
                                  src={image.imgSrc}
                                  alt={`Footer Logo ${index}`}
                                  className="footerlogos"
                                  height={image.title === "Mail" ? "22px" : "28px"}
                                  // height={
                                  //   image.title === "Game" ? "24px" : "28px"
                                  // }
                                />
                              </div>

                              {image.showIcon ? (
                                <div
                                  className={`plus-minus-icon-${image.key} ${
                                    popoverOpen ? "minus-icon" : ""
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleImageInFooter(image, image.key, 2);
                                  }}
                                >
                                  <FaMinusCircle className="plus-minus-icon" />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )}

                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[index]}
                          target={`footer-logo-${index}`}
                          toggle={() => toggleTooltip(index)}
                        >
                          {image.title}
                        </Tooltip>
                      </div>
                    )
                  );
                })}

                <div
                  className="bg-icon-plus "
                  ref={popoverTargetRef}
                  style={{
                    backgroundColor: theme === "dark" ? "#373e63" : "#f7f7f7",
                  }}
                >
                  <img
                    src={d_plus_logo}
                    alt=""
                    height="15"
                    id="PopoverTop"
                    onClick={togglePopover}
                  />
                </div>

                <Popover
                  placement="top"
                  isOpen={popoverOpen}
                  target="PopoverTop"
                  toggle={togglePopover}
                  innerRef={popoverRef}
                  className="popover-images"
                  style={{
                    backgroundColor: theme === "dark" ? "#2a2b2e" : "#e6e6e69c",
                    borderRadius: "6px",
                  }}
                >
                  <PopoverBody>
                    {footerApps.length === 0 ||
                    footerApps.every(
                      (image) =>
                        !(image.popOver && image.footer_visibility == 2)
                    ) ? (
                      <div className="text-center">
                        {/* <img src={d_plus_logo} alt="default" height="28px" /> */}
                        {/* <p className="fs-9px mt-1 opacity-75 mb-0">Apps</p> */}
                      </div>
                    ) : (
                      footerApps.map((image, index) => {
                        return (
                          image.popOver &&
                          image.footer_visibility === "2" && (
                            <div
                              key={index}
                              className="footerlogosPop-container text-center pointer"
                              onClick={() => {
                                toggleImageInFooter(image, image.key, 1);
                              }}
                            >
                              {loadingApp === image.key ? (
                                <Spinner
                                  size="sm"
                                  className="mb-2 spinner-md"
                                />
                              ) : (
                                <>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <div
                                      className="position-relative footer-apps"
                                      style={{
                                        backgroundColor:
                                          theme === "dark"
                                            ? "#4442499e"
                                            : "#f7f7f7",
                                      }}
                                    >

                                      
                                      <img
                                        src={image.imgSrc}
                                        alt={`Popover Logo ${index}`}
                                        className="footerlogosPop"
                                        style={image.style}
                                      />
                                      <div
                                        className={` plus-minus-icon-${image.key}`}
                                        style={{
                                          color:
                                            theme === "dark"
                                              ? "#b6c6e3"
                                              : "#526484",
                                        }}
                                      >
                                        <FaPlusCircle
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            toggleImageInFooter(
                                              image,
                                              image.key,
                                              1
                                            );
                                          }}
                                          className="plus-minus-icon"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <p
                                    className="fs-9px mt-1 opacity-75 mb-0"
                                    style={{
                                      color:
                                        theme === "dark"
                                          ? "#b6c6e3"
                                          : "#526484",
                                    }}
                                  >
                                    {image.title}
                                  </p>
                                </>
                              )}
                            </div>
                          )
                        );
                      })
                    )}
                  </PopoverBody>
                </Popover>
              </div>
            </div>

            <div className="col-1 col-lg-2 px-0 pe-lg-3 mobile-view  my-auto">
              <div className="d-lg-none ">
                <UncontrolledDropdown
                  direction="up"
                  className="d-flex justify-content-center align-items-center"
                >
                  <DropdownToggle
                    className="btn-action p-0  border-0 bg-transparent pe-3 pb-1 "
                    color="primary"
                  >
                    <span>
                      <IoMenu
                        name="menu"
                        className="theme-menu"
                        style={{
                          color: theme === "dark" ? "#fff" : "#5c5c5c",
                        }}
                      />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      backgroundColor: theme === "dark" ? "#2a2b2e" : "#fff",
                      color: theme === "dark" ? "#fff" : "#5c5c5c",
                    }}
                  >
                    <div className="link-list-opt  ">
                      {!isLoggedIn ? null : (
                        //  <li className="py-2 m-2 border-bottom">{authenticated && sidebarMobile && <TalkComponent />}</li>
                        <li className="py-2 m-2 border-bottom">
                          <TalkComponent />
                        </li>
                      )}
                      <li className="">
                        <div className="d-flex align-items-center footer-bottom  m-2 border-bottom justify-content-center ">
                          {/* <Icon name="power" className="text-blue-color fs-10px  rounded-2" id="power-button"></Icon> */}
                          <a
                            href="https://silocloud.com/"
                            className="mb-0 fs-9px  RFootertext"
                            style={{
                              color: theme === "dark" ? "#fff" : "#5c5c5c",
                            }}
                          >
                            About
                          </a>
                          <a
                            href="https://silocloud.io/privacy-policy"
                            className="mb-0 fs-9px RFootertext"
                            style={{
                              color: theme === "dark" ? "#fff" : "#5c5c5c",
                            }}
                          >
                            Privacy
                          </a>
                          <a
                            href="https://silocloud.io/terms-condition"
                            className="mb-0 fs-9px RFootertext"
                            style={{
                              color: theme === "dark" ? "#fff" : "#5c5c5c",
                            }}
                          >
                            Terms
                          </a>
                          {/* {/ <img src={img} alt="Settings Icon" className="me-0" height="15px" onClick={toggle} /> /} */}
                        </div>
                      </li>

                      <li className="py-2">
                        <div className="storage-block rounded-3 px-2 py-1 ms-1">
                          <a href="https://storage.silocloud.io/">
                            <div
                              className="d-flex align-items-center  mb-1"
                              style={{
                                color: theme === "dark" ? "#fff" : "#5c5c5c",
                              }}
                            >
                              <FaDatabase name="db-fill" className="fs-5" />
                              {!loading ? (
                                <p className="fs-9px mb-0 ms-1 opacity-75">
                                  Cloud Storage {storageDetails.usedStorageText}
                                  /{storageDetails.planStorageText} Used
                                </p>
                              ) : (
                                <Skeleton
                                  width={100}
                                  height={8}
                                  baseColor="#c5bfe0"
                                />
                              )}
                            </div>
                            <div className="">
                              <Progress
                                value={storageDetails.percentageUsed}
                                color="info"
                                className="progress-storage"
                              />
                            </div>
                          </a>
                        </div>
                      </li>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="d-none d-lg-block">
                {!isLoggedIn ? null : <TalkComponent />}
                <div className="d-flex align-items-center footer-bottom justify-content-center justify-content-lg-end">
                
                  <a
                    href="https://silocloud.com/"
                    className="mb-0 fs-9px px-2"
                    style={{
                      color: theme === "dark" ? "#fff" : "#5c5c5c",
                    }}
                  >
                    About
                  </a>
                  <a
                    href="https://silocloud.io/privacy-policy"
                    className="mb-0 fs-9px px-2"
                    style={{
                      color: theme === "dark" ? "#fff" : "#5c5c5c",
                    }}
                  >
                    Privacy
                  </a>
                  <a
                    href="https://silocloud.io/terms-condition"
                    className="mb-0 fs-9px px-2"
                    style={{
                      color: theme === "dark" ? "#fff" : "#5c5c5c",
                    }}
                  >
                    Terms
                  </a>

                  {/* {/ <img src={img} alt="Settings Icon" className="ms-2" height="15px" onClick={toggle} /> /} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;

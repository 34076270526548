import React, { useEffect, useState } from "react";
import { getCookie } from "../../../utils/utils";
import UserAvatar from "./UserAvatar"; 
import dp from "../../../assets/Images/applogos2/black_theme/dp.png";

const AccountAvatar = ({ profileName }) => {
  const [isAvatarImage, setIsAvatarImage] = useState(false);
  const [imageUrl, setFileURL] = useState(null);

  useEffect(() => {
    getProfileImage();
  }, []);

  const getProfileImage = async () => {
    try {
      let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/account/get-only-profile-img`, {
        method: "POST",
        cache: "no-store",
        headers: {
          authToken: getCookie("authToken", null),
        },
      });
      if (res.ok) {
        const blob = await res.blob();
        const imageUrl = URL.createObjectURL(blob);
        setIsAvatarImage(true);
        setFileURL(imageUrl);
      }
    } catch (error) {
      console.error("Error fetching profile image", error);
    }
  };

  if (isAvatarImage) {
    return <UserAvatar image={imageUrl} style={{width :"-webkit-fill-available"}}  imageAlt={`profile-img`} className="rounded-circle " />;
  }

  return (
    <div className="account-avatar ">
      <img src={dp} alt={profileName} style={{
        width: "40px"
      }}/>

    </div>
  );
};

export default AccountAvatar;

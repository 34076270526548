import React from "react";

const Details = ({ modelInfo }) => {
  return (
    <div>
      <div className=" ">
        <div className="d-flex justify-content-center">
          <span className="headingViewer mt-1 mb-1 border-bottom border-dark-subtle pl-2">
            Details
          </span>
        </div>
        <div className="details-content mt-2 ml-1">
          <p>Vertices: {modelInfo.vertices}</p>
          <p>Triangles: {modelInfo.triangles}</p>
          <p>Size X: {modelInfo.sizeX.toFixed(2)}</p>
          <p>Size Y: {modelInfo.sizeY.toFixed(2)}</p>
          <p>Size Z: {modelInfo.sizeZ.toFixed(2)}</p>
          {/* <p>Volume: {modelInfo.volume.toFixed(2)}</p> */}
          {/* <p>Surface Area: {modelInfo.surfaceArea.toFixed(2)}</p> */}
        </div>
      </div>
    </div>
  );
};

export default Details;

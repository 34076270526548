import React, { useState } from "react";
import FileDropZone from "./FileDropZone.jsx";
import "../Home/Home.css";
import { useTheme } from "../../contexts/ThemeContext";


function App({ onDrop }) {
  const { theme } = useTheme();
  const [files, setFiles] = useState([]);
  return (
    <div className={`other-component ${theme}`}>
     
        <FileDropZone
          onDrop={{ onDrop }}
          onFilesSelected={setFiles}
          width="300px"
          height="400px"
        />
      </div>
  
  );
}

export default App;

import React from "react";
const Materials = ({ material }) => {
  return (
    <>
      <div className=" ">
        <div className="d-flex justify-content-center">
          <span className="headingViewer mt-1 mb-1 border-bottom border-dark-subtle pl-2">
            Materials
          </span>
        </div>
        <div className="details-content mt-2 ml-1">
          {material ? (
            <>
              <p>Source: Model</p>
              <p>Type: {material.type || "N/A"}</p>
              <p>
                Color:{" "}
                {material.color ? `#${material.color.getHexString()}` : "N/A"}
              </p>
              <p>
                Metalness:{" "}
                {material.metalness !== undefined
                  ? `${material.metalness * 100}%`
                  : "N/A"}
              </p>
              <p>
                Roughness:{" "}
                {material.roughness !== undefined
                  ? `${material.roughness * 100}%`
                  : "N/A"}
              </p>
              <p>
                Opacity:{" "}
                {material.opacity !== undefined
                  ? `${material.opacity * 100}%`
                  : "N/A"}
              </p>
              <p>Diffuse Map: {material.map ? material.map.name : "N/A"}</p>
            </>
          ) : (
            <p>Material Info: None<br/>
            <div className="roboto-regular mt-2">

            Please Click On Model To See Information.
            </div>
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Materials;

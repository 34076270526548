import React from "react";
import { IoEyeOffOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import "./Feature.css";
const Meshes = ({ props }) => {
  const {
    modelParts,
    selectedPart,
    selectPart,
    toggleVisibility,
    theme,
    selectedPartId,
  } = props;
  const selP = selectedPart.name;

  return (
    <div>
      <div className="d-flex justify-content-between">
        <span className="headingViewer mt-1 mb-1 border-bottom border-dark-subtle">
          Meshes
        </span>
      </div>

      {modelParts &&
        modelParts.map((part) => (
          <div
            key={part.id}
            className={`d-flex justify-content-between  custombuttonhover mb-1
           ${selP == part.name ? " WhenModelPatSelected" : " "}
            ${selectedPartId === part.id ? "selected" : ""}`}
            onClick={() => selectPart(part.id, part.name)}
          >
            <span className="custom-button ps-1">{part.name}</span>
            <div className="d-flex justify-content-center align-items-center">
              <button
                onClick={(e) => toggleVisibility(part.id, e)} // Eye Button
                className="custom-button2 pe-2 "
                style={{
                  color: theme === "light" ? "#2A2B2E" : "#FFFFFF",
                }}
              >
                <span className="fs-4 d-flex justify-content-center align-item-center">
                  {part.visible ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </span>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Meshes;

import React from "react";
import ColorPicker from "./Colorpicker";
// import BackgroundImage from "./BackgroundImage";

const Settings = ({ color1, setColor1 }) => {
  return (
    <>
      <div className=" row ">
        <div className="d-flex justify-content-center">
          <span className="headingViewer mt-1 mb-1 border-bottom border-dark-subtle pl-2">
            Model Display
          </span>
        </div>
        <div className="  d-flex  ">
          <div>
            <ColorPicker color1={color1} setColor1={setColor1} />
            {/* <BackgroundImage /> */}
          </div>
        </div>
      </div>
      <div className=""></div>
    </>
  );
};

export default Settings;

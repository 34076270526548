import React, { useState } from "react";
// import Inner from "./Inner";
import InnerModel from "./InnerModel.jsx";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
  import useStorageUsage from "../hooks/useStorageUsage.jsx";
  import { Link } from "react-router-dom";
import Inner from "./Inner.jsx";

const FileDropZone = () => {
  const [authToken] = useState(Cookies.get("authToken"));
  const navigate = useNavigate();
  const { storageDetails, loading, error } = useStorageUsage();
  const handleDrop = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile);
      const extension = selectedFile.name.split(".").pop();

      // Log for debugging
      console.log("Selected File:", selectedFile);
      console.log("File URL:", fileUrl);
      console.log("File Extension:", extension);

      // Store the extension in local storage
      localStorage.setItem("fileExtension", extension);
      console.log("Local Storage:", localStorage.getItem("fileExtension")); // Debugging

      // Set the cookie with the file extension, expires in 10 minutes
      Cookies.set("fileExtension", extension, { expires: 5 / 1440 });
      console.log("Cookie:", Cookies.get("fileExtension")); // Debugging

      // Navigate to the model URL
      // if(authToken){
        navigate(`/model2/${encodeURIComponent(fileUrl)}`);
      // }
      // else{
      //   navigate(`/model/${encodeURIComponent(fileUrl)}`);
      // }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div className="px-2 py-1">
      <div
        className="file-drop-zone dropHeight position-relative"
        style={{
          border: "2px dashed #ccc",
          display: "flex",
          alignItems: "center",
          justifyContent: "center", 
          height: "calc(100vh - 123px)",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="w-full top-0  position-absolute">
          {storageDetails.storageFilledAlert && (
            <div
              className="alert alert-warning  m-1 m-md-2  ps-2 ps-md-4  "
              role="alert"
            >
              <div className="w-100">
                <div
                  className="roboto-large "
                  style={{
                    color: "#1b3d78",
                  }}
                >
                  Storage Filled Alert!
                </div>
                <div className="roboto-medium">
                  Your storage has been filled with 90% or above. Please
                  <Link
                    to={storageDetails.upgradeLink}
                    style={{ color: "#313a4b" }}
                  >
                    {" "}
                    upgrade
                  </Link>{" "}
                  the plan.
                </div>
              </div>
            </div>
          )}
        </div>
        {!authToken ? <Inner /> : <InnerModel />}
        {/* {!authToken ? collection : <InnerModel />} */}
      </div>
    </div>
  );
};

export default FileDropZone;

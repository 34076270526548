
import axios from "axios";
import { getCookie } from "../../utils/utils";

// export const uploadFile = async (filePath, thumbnailPath) => {
//     let data = await axios.post("https://api.silocloud.io/api/v1/three-d/upload-threed-file", {
//         filePath: filePath,
//         thumbnailPath: thumbnailPath,
//     },
//         {
//             headers: {
//                 'authToken': getCookie("authToken", null),
//             }
//         }
//     )
//     return data;
// }

export const uploadThreeDFile = async ({modelFile, thumbnailFile}) => {

    const formData = new FormData();
    formData.append("file_path", modelFile);

    if (thumbnailFile) {
        formData.append("thumbnail_path", thumbnailFile);
    }

    const data = await axios.post(
        "https://api.silocloud.io/api/v1/three-d/upload-threed-file", 
        formData, 
        {
            headers: {
                'authToken': getCookie("authToken", null),
            }
        }
    );

    return data;
};

// const xhr = new XMLHttpRequest();
    // xhr.open(
    //   "POST",
    //   "https://api.silocloud.io/api/v1/three-d/upload-threed-file",
    //   true
    // );
    // xhr.setRequestHeader("authToken", authToken);

    // xhr.upload.onprogress = (event) => {
    //   if (event.lengthComputable) {
    //     const percentComplete = Math.round((event.loaded / event.total) * 100);
    //     setUploadProgress(percentComplete);
    //   }
    // };

    // xhr.onload = () => {
    //   setUploading(false);
    //   if (xhr.status === 200) {
    //     toast.success("Files uploaded successfully");
    //     handleClose();
    //   } else {
    //     console.error("Error uploading files:", xhr.statusText);
    //     toast.error("Error uploading files: " + xhr.statusText);
    //   }
    // };

    // xhr.onerror = () => {
    //   setUploading(false);
    //   console.error("Error uploading files:", xhr.statusText);
    //   toast.error("Error uploading files: " + xhr.statusText);
    // };

    // xhr.onabort = () => {
    //   setUploading(false);
    //   setUploadProgress(0);
    //   toast.error("Upload cancelled");
    // };

    // xhr.send(formData);

    // abortController.signal.addEventListener("abort", () => {
    //   xhr.abort();
    // });

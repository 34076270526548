import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Hero from "../components/Home/Hero.jsx";
import { useTheme } from "../contexts/ThemeContext";
import Meshes from "../components/Features/Meshes.jsx";
import Details from "../components/Features/Details.jsx";
import Settings from "../components/Features/Settings.jsx";
import Materials from "../components/Features/Materials.jsx";
import { RxCube } from "react-icons/rx";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { PiCubeFocusLight } from "react-icons/pi";
import Cookies from "js-cookie";
import logoR from "../assets/Images/3dviewer_net_logo.png";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { getCookie } from "../utils/utils.js";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { useNavigate } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
const ModelViews2 = () => {
  const { id } = useParams();
  const [color1, setColor1] = useState("#ffffff");
  const authToken = Cookies.get("authToken");

  const [isLoading, setIsLoading] = useState(true);
  const [modelParts, setModelParts] = useState([]);
  const [modelInfo, setModelInfo] = useState({
    vertices: 0,
    triangles: 0,
    sizeX: 0,
    sizeY: 0,
    sizeZ: 0,
    volume: 0,
    surfaceArea: 0,
  });
  const [selectedPartId, setSelectedPartId] = useState(null); // State to track selected part ID
  const [selectedPart, setSelectedPart] = useState({ id: null, name: null }); // State to track selected part
  const [visibleSection, setVisibleSection] = useState("meshes"); // state to track visible section
  const { theme } = useTheme();
  const mountRef = useRef(null); // Reference to mount point for renderer
  const sceneRef = useRef(null); // Reference to the scene
  const [backgroundColor, setBackgroundColor] = useState(null); // State for background color
  const [materialInfo, setMaterialInfo] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const rendererRef = useRef(null);
  const clickTimeoutRef = useRef(null);

  // window.onload = function () {
  //   const currentUrl = window.location.href;

  //   if (currentUrl.includes("/model2/")) {
  //     if (window.performance) {
  //       if (performance.navigation.type === 1) {
  //         window.location.href = "https://3d.silocloud.io/";
  //       } else {
  //         console.log("Not directed");
  //       }
  //     }
  //   }
  // };
  useEffect(() => {
    // Get the current URL
    const currentURL = window.location.href;
    const baseURL = "https://3d.silocloud.io/";

    // Check if the URL starts with the base URL
    if (currentURL.startsWith(baseURL)) {
      // Extract the part after the base URL
      const path = currentURL.substring(baseURL.length);

      // Check if the path starts with 'model2/blob:'
      const regex = /^model2\/blob:/;
      if (regex.test(path)) {
        // Construct the new URL to redirect to
        const newURL = "https://3d.silocloud.io/"; // Adjust as needed

        // Redirect to the new URL
        window.location.replace(newURL);
      }
    }
  }, []);

  useEffect(() => {
    const currentMount = mountRef.current;
    if (!rendererRef.current) {
      rendererRef.current = new THREE.WebGLRenderer({ antialias: true });
      rendererRef.current.setSize(
        mountRef.current.clientWidth,
        mountRef.current.clientHeight
      );
      rendererRef.current.setPixelRatio(window.devicePixelRatio);
      rendererRef.current.physicallyCorrectLights = true;
      rendererRef.current.shadowMap.enabled = true;
      rendererRef.current.shadowMap.type = THREE.PCFSoftShadowMap;
      mountRef.current.appendChild(rendererRef.current.domElement);
    }

    // Set up scene
    const scene = new THREE.Scene();
    sceneRef.current = scene; // Assign scene to the ref

    if (theme === "dark") {
      scene.background = new THREE.Color(0x212529);
    } else if (backgroundColor != null) {
      scene.background = new THREE.Color(backgroundColor);
    } else {
      scene.background = new THREE.Color(0xf8f9fa);
    }

    // Set up camera
    const camera = new THREE.PerspectiveCamera(
      90,
      window.innerWidth / window.innerHeight,
      0.25,
      50
    );
    camera.position.set(-1.8, 0.9, 2.7);

    // Set up renderer

    const renderer = rendererRef.current;

    // Set up OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = true; // Ensure zooming is enabled
    controls.zoomSpeed = 1.0; // Adjust zoom speed if necessary
    controls.maxPolarAngle = Math.PI; // Allow full vertical rotation

    const cameraPosition = camera.position.clone(); // Get camera position

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1); //apply like a sun light
    directionalLight.position.copy(cameraPosition);
    directionalLight.position.add(new THREE.Vector3(0, 1, 0)); // Example offset, adjust as needed

    scene.add(directionalLight);

    // Key Light (Front Top Right)
    const keyLight = new THREE.DirectionalLight(0xffffff, 1);
    keyLight.position.set(10, 10, 10).normalize();
    scene.add(keyLight);

    // Fill Light (Front Top Left)
    const fillLight = new THREE.DirectionalLight(0xffffff, 1);
    fillLight.position.set(-5, 10, 10).normalize();
    scene.add(fillLight);

    // Back Light (Back Bottom)
    const backLight = new THREE.DirectionalLight(0xffffff, 1);
    backLight.position.set(-10, -10, -10).normalize();
    scene.add(backLight);

    // Back Light 2 (Back Bottom Left)
    const backLight2 = new THREE.DirectionalLight(0xffffff, 1);
    backLight2.position.set(-5, -5, 0).normalize();
    scene.add(backLight2);

    // Side Light (Right)
    const sideLightRight = new THREE.DirectionalLight(0xffffff, 1);
    sideLightRight.position.set(10, 0, 0).normalize();
    scene.add(sideLightRight);

    // Side Light (Left)
    const sideLightLeft = new THREE.DirectionalLight(0xffffff, 1);
    sideLightLeft.position.set(-10, 0, 0).normalize();
    scene.add(sideLightLeft);

    // Top Light
    const topLight = new THREE.DirectionalLight(0xffffff, 1);
    topLight.position.set(0, 10, 0).normalize();
    scene.add(topLight);

    // Bottom Light
    const bottomLight = new THREE.DirectionalLight(0xffffff, 1);
    bottomLight.position.set(0, -10, 0).normalize();
    scene.add(bottomLight);
    // Point lights emit light uniformly in all directions from a specific point in space.
    const pointLight = new THREE.PointLight(0xffffff, 1, 10); // Color: white, Intensity: 1, Distance: 10
    pointLight.position.set(0, 3, 0);
    scene.add(pointLight);

    // Spot lights emit light in a cone shape from a specific position, with a specified direction and angle.
    const spotLight = new THREE.SpotLight(0xffffff, 1); // Color: white, Intensity: 1
    spotLight.position.set(0, 5, 0); // Set light position
    spotLight.angle = Math.PI / 4; // 45 degree angle
    spotLight.penumbra = 0.5; // Soft edges
    scene.add(spotLight);

    const ambientLight = new THREE.AmbientLight(0xffffff, 1); //It comes from everywhere
    scene.add(ambientLight);

    const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.6); // Sky color: green, Ground color: red, Intensity: 0.6
    scene.add(hemisphereLight);

    const loadModel = async (id, useDraco = true) => {
      const extension = getCookie("fileExtension");
      const extension1 = localStorage.getItem("fileExtension");

      if (!extension && !extension1) {
        console.error("No file extension found.");
        return;
      }

      let loader;
      const fileExtension = extension || extension1;

      switch (fileExtension) {
        case "gltf":
        case "glb":
          loader = new GLTFLoader();
          loader = new GLTFLoader();
          // Add DracoLoader to GLTFLoader
          const dracoLoader = new DRACOLoader();
          dracoLoader.setDecoderPath(
            "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
          );
          loader.setDRACOLoader(dracoLoader);
          break;
        case "stl":
          loader = new STLLoader();
          break;
        case "fbx":
          loader = new FBXLoader();
          break;
        case "obj":
          loader = new OBJLoader();
          break;
        default:
          console.error("Unsupported file format");
          return;
      }

      try {
        const loadedModel = await new Promise((resolve, reject) => {
          loader.load(id, resolve, undefined, reject);
        });

        let model;
        if (fileExtension === "stl") {
          const envTexture = new THREE.CubeTextureLoader().load([
            "img/px_50.png",
            "img/nx_50.png",
            "img/py_50.png",
            "img/ny_50.png",
            "img/pz_50.png",
            "img/nz_50.png",
          ]);
          envTexture.mapping = THREE.CubeReflectionMapping;

          const material = new THREE.MeshPhysicalMaterial({
            color: 0x9f9797,
            envMap: envTexture,
            metalness: 0.5,
            roughness: 0.1,
            transparent: true,
            transmission: 1.0,
            clearcoat: 1.0,
            clearcoatRoughness: 0.25,
          });

          model = new THREE.Mesh(loadedModel, material);
        } else {
          model = loadedModel.scene || loadedModel;
        }

        const parts = [];
        let vertices = 0;
        let triangles = 0;
        const box = new THREE.Box3().setFromObject(model);
        const size = box.getSize(new THREE.Vector3());
        let volume = 0;
        let surfaceArea = 0;
        const textureLoader = new TextureLoader();

        model.traverse((node) => {
          if (node.isMesh) {
            node.castShadow = true;
            node.receiveShadow = true;

            if (
              fileExtension === "fbx" ||
              (fileExtension === "obj" && node.material)
            ) {
              if (node.material.map && node.material.map.image) {
                console.log(node.material.map.image);

                textureLoader.load(node.material.map.image.src, (texture) => {
                  node.material.map = texture;
                  console.log(node.material.map);
                  node.material.needsUpdate = true;
                });
              }
              if (fileExtension === "obj") {
                if (node.material) {
                  // Ensure the material is one that supports color
                  if (
                    node.material instanceof THREE.MeshBasicMaterial ||
                    node.material instanceof THREE.MeshStandardMaterial ||
                    node.material instanceof THREE.MeshLambertMaterial
                  ) {
                    // Check if node.material.color is defined
                    if (node.material.color) {
                      // Apply color to the material for obj files
                      node.material.color.set(0x9f9797);
                    } else {
                      console.error("node.material.color is undefined");
                    }
                  } else {
                    console.error(
                      "node.material is not a color-supporting material"
                    );
                  }
                } else {
                  console.error("node.material is undefined");
                }
              }
              parts.push({
                id: node.uuid,
                name: node.name || node.uuid,
                visible: true,
                selected: false,
              });
            } else {
              const originalMaterial = node.material.clone();
              parts.push({
                id: node.uuid,
                name: node.name || node.uuid,
                visible: true,
                selected: false,
                node,
                originalMaterial,
              });
            }

            const geometry = node.geometry;
            vertices += geometry.attributes.position.count;

            if (geometry.index !== null) {
              triangles += geometry.index.count / 3;
            } else {
              triangles += geometry.attributes.position.count / 3;
            }

            volume += calculateVolume(geometry);
            surfaceArea += calculateSurfaceArea(geometry);
          }
        });

        setModelParts(parts);
        setModelInfo({
          vertices,
          triangles,
          sizeX: size.x,
          sizeY: size.y,
          sizeZ: size.z,
          volume,
          surfaceArea,
        });

        scene.add(model);

        const boxCenter = box.getCenter(new THREE.Vector3());

        controls.maxDistance = size.length() * 10;
        camera.near = size.length() / 100;
        camera.far = size.length() * 100;
        camera.updateProjectionMatrix();

        camera.position.copy(boxCenter);
        camera.position.x += size.length() / 2;
        camera.position.y += size.length() / 5;
        camera.position.z += size.length() / 2;
        camera.lookAt(boxCenter);

        controls.target.copy(boxCenter);
        controls.update();

        setIsLoading(false);
      } catch (error) {
        console.error("An error occurred during model loading:", error);
        setIsLoading(false);
      }
    };

    if (id) {
      loadModel(decodeURIComponent(id));
      sessionStorage.setItem("refreshed", "true");
    }

    const onSingleClick = (event) => {
      // console.log("Double Click");

      setModelParts((prevParts) =>
        prevParts.map((part) => {
          if (part.selected) {
            part.node.material = part.originalMaterial;
          }
          return part;
        })
      );
    };

    const onMouseClick = (event) => {
      // console.log("Single Click");

      // Calculate mouse position relative to the canvas
      const canvasBounds = renderer.domElement.getBoundingClientRect();
      const mouseX =
        ((event.clientX - canvasBounds.left) / canvasBounds.width) * 2 - 1;
      const mouseY =
        -((event.clientY - canvasBounds.top) / canvasBounds.height) * 2 + 1;

      // Perform picking based on mouse coordinates
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(new THREE.Vector2(mouseX, mouseY), camera);

      // Check intersections with model parts
      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const intersectedNode = intersects[0].object;
        const material = intersectedNode.material;
        setMaterialInfo(material);

        setModelParts((prevParts) =>
          prevParts.map((part) => {
            if (part.node === intersectedNode) {
              const selectedMaterial = new THREE.MeshBasicMaterial({
                color: 0x3393bd, // Light blue color for highlight
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 0.5,
              });
              part.node.material = selectedMaterial;

              return { ...part, selected: true };
            } else {
              if (part.selected) {
                part.node.material = part.originalMaterial;
              }
              return { ...part, selected: false };
            }
          })
        );

        setSelectedPart({
          id: intersectedNode.uuid,
          name: intersectedNode.name || intersectedNode.uuid,
        });
      }
    };

    // Handle single and double click with a timeout
    const handleClick = (event) => {
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
        clickTimeoutRef.current = null;
        onSingleClick(event); // Handle double-click logic
      } else {
        clickTimeoutRef.current = setTimeout(() => {
          onMouseClick(event); // Handle single-click logic
          clickTimeoutRef.current = null;
        }, 250); // Adjust delay as needed
      }
    };

    // Update canvas size on window resize
    const handleResize = () => {
      renderer.setSize(
        mountRef.current.clientWidth,
        mountRef.current.clientHeight
      );
      camera.aspect =
        mountRef.current.clientWidth / mountRef.current.clientHeight;
      camera.updateProjectionMatrix();
    };

    const handleDrop = (event) => {
      event.preventDefault();
      const dropFile = event.dataTransfer.files[0];
      if (dropFile) {
        const fileUrl = URL.createObjectURL(dropFile);
        const extension = dropFile.name.split(".").pop();

        localStorage.setItem("fileExtension", extension);

        Cookies.set("fileExtension", extension, { expires: 5 / 1440 });

        // Navigate to the model URL
        navigate(`/model2/${encodeURIComponent(fileUrl)}`);
      }
    };

    const handleDragOver = (event) => {
      event.preventDefault();
    };

    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", handleDrop);

    window.addEventListener("click", handleClick);
    window.addEventListener("resize", handleResize);

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", handleDrop);
      window.removeEventListener("click", handleClick);
      window.removeEventListener("resize", handleResize);
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, [id, theme, authToken, backgroundColor]);

  useEffect(() => {
    sceneRef.current.background = new THREE.Color(color1);
  }, [color1]);

  const calculateVolume = (geometry) => {
    // Volume calculation logic
    return 0; // Placeholder
  };

  const calculateSurfaceArea = (geometry) => {
    // Surface area calculation logic
    return 0; // Placeholder
  };

  const toggleVisibility = (partId, e) => {
    e.stopPropagation();
    setModelParts((prevParts) => {
      return prevParts.map((part) => {
        if (part.id === partId) {
          const newVisibility = !part.visible;
          if (part.node) {
            part.node.visible = newVisibility;
          }
          return { ...part, visible: newVisibility };
        }
        return part;
      });
    });
  };

  const selectPart = (partId, partName) => {
    setModelParts((prevParts) => {
      const updatedParts = prevParts.map((part) => {
        if (part.id === partId) {
          const isSelected = !part.selected;

          if (isSelected) {
            const selectedMaterial = new THREE.MeshBasicMaterial({
              color: 0x3393bd,
              side: THREE.DoubleSide,
              transparent: true,
              opacity: 0.5,
            });
            part.node.material = selectedMaterial;
          } else {
            part.node.material = part.originalMaterial;
          }

          return { ...part, selected: isSelected };
        }
        part.node.material = part.originalMaterial;
        return { ...part, selected: false };
      });

      return updatedParts;
    });

    setSelectedPart({ id: partId, name: partName });
  };

  return (
    <>
      <div>
        <Hero />
      </div>

      <div className={`other-component asdfghjkl pt-1 ${theme}`}>
        <div className="LoaderMain">
          {isLoading && (
            <div
              className="d-flex justify-content-center align-items-center shadow loaderBackground position-absolute  text-center p-2  w-50 h-50"
              style={{
                backgroundColor: theme === "dark" ? "#2A2B2E" : "#d3d3d3",
              }}
            >
              <div className="text-center ">
                <div className="Customloader mb-3">
                  <img src={logoR} alt="Loading..." />
                </div>
                <div className="fs-2 pt-2 text-white">Loading Model...</div>
              </div>
            </div>
          )}

          <div className="row px-1">
            <div className="col-4 d-none d-md-block col-lg-3">
              <div className="row">
                <div className="col-xl-2 col-md-3 border-end border-dark-subtle text-center justify-content-center">
                  <ul className="nav nav-pills nav-flush mx-1">
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-right-1">Meshes</Tooltip>}
                    >
                      <div
                        className={`SecondNavButton3 ${
                          visibleSection === "meshes" ? "active" : ""
                        }`}
                        onClick={() => setVisibleSection("meshes")}
                      >
                        <div>
                          <RxCube className="fs-1 p-1 m-1" />
                        </div>
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-right-2">Details</Tooltip>}
                    >
                      <div
                        className={`mt-1 SecondNavButton3 ${
                          visibleSection === "details" ? "active" : ""
                        }`}
                        onClick={() => setVisibleSection("details")}
                      >
                        <div>
                          <HiOutlineClipboardDocumentList className="fs-1 p-1 m-1" />
                        </div>
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-right-3">Settings</Tooltip>}
                    >
                      <div
                        className={`mt-1 SecondNavButton3 ${
                          visibleSection === "settings" ? "active" : ""
                        }`}
                        onClick={() => setVisibleSection("settings")}
                      >
                        <div>
                          <IoSettingsOutline className="fs-1 p-1 m-1" />
                        </div>
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-right-4">Materials</Tooltip>
                      }
                    >
                      <div
                        className={`mt-1 SecondNavButton3 ${
                          visibleSection === "materials" ? "active" : ""
                        }`}
                        onClick={() => setVisibleSection("materials")}
                      >
                        <div>
                          <PiCubeFocusLight className="fs-1 p-1 m-1" />
                        </div>
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-right-5">Files</Tooltip>}
                    >
                      <div
                        className={`mt-1 SecondNavButton3 ${
                          visibleSection === "files" ? "active" : ""
                        }`}
                        onClick={() => setVisibleSection("files")}
                      >
                        <div>{/* Add an icon or content here if needed */}</div>
                      </div>
                    </OverlayTrigger>
                  </ul>
                </div>
                <div className="col-xl-10  col-md-9 ">
                  <div className="scrollable-column overflow-x-hidden">
                    <div>
                      {visibleSection === "meshes" && (
                        <Meshes
                          props={{
                            modelParts: modelParts,
                            selectedPartId: selectedPartId,
                            selectPart: selectPart,
                            selectedPart: selectedPart,
                            toggleVisibility: toggleVisibility,
                            theme: theme,
                          }}
                        />
                      )}
                      {visibleSection === "details" && (
                        <Details modelInfo={modelInfo} />
                      )}
                      {visibleSection === "settings" && (
                        <Settings
                          color1={color1}
                          setColor1={setColor1}
                          handleColorChange={setColor1}
                        />
                      )}
                      {visibleSection === "materials" && (
                        <Materials material={materialInfo} />
                      )}
                      {/* {visibleSection === "files" && <File/>} */}
                      {/* {visibleSection === "files" && <File files={files}/>} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-9 p-0">
              <div
                style={{
                  height: "calc(100vh - 130px)",
                  width: "100%",
                  // overflow: "hidden",
                  border: "1px solid #7f7f7f",
                }}
              >
                <div
                  ref={mountRef}
                  id="ModelLoding"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModelViews2;

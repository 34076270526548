import React, { useState, useRef, useEffect } from "react";
import { Button } from "reactstrap";
import { SketchPicker } from "react-color";
import "./Feature.css";

const ColorPickerComponent = ({ color1, setColor1 }) => {
  const [showPicker1, setShowPicker1] = useState(false);
  const pickerRef = useRef(null);

  const handleButtonClick1 = () => {
    setShowPicker1(!showPicker1);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker1(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleColorChange1 = (color) => {
    setColor1(color.hex); // Update color1 state in ModelViews2
  };

  return (
    <div className="d-flex py-1 ">
      <div className="py-1 align-content-center">
        <div className="d-flex align-items-center">
          <Button
            outline
            color="primary"
            className="colorpickerbutton me-2"
            style={{ backgroundColor: color1 }}
            onClick={handleButtonClick1}
          ></Button>
          <div className="align-content-center">Background Color</div>
        </div>

        {showPicker1 && (
          <div ref={pickerRef} className="position-absolute">
            <SketchPicker color={color1} onChange={handleColorChange1} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPickerComponent;

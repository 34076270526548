import React, { createContext, useEffect, useState } from "react";
import Homepage from "./pages/Homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "./contexts/ThemeContext";
import ModelViews2 from "./pages/ModelViews2.jsx";
import ModelViews1 from "./pages/ModelViewer1.jsx";
import "./App.css";
import Cookies from "js-cookie";
import ModelViewerget from "./pages/ModelViwerget.jsx";
import Collection from "./pages/GetModel.jsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./components/https/index.js";

export const TriggerBtnContext = createContext();

function App() {
  const [triggerFetch, setTriggerFetch] = useState(false);
  const toggleFetch = () => {
    setTriggerFetch((prev) => !prev);
  };

  useEffect(() => {
    // Set cookie with current URL
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    Cookies.set("currentURL", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });

    // Redirect based on navigation type
    // const handleRedirect = () => {
    //   const currentUrl = window.location.href;
    //   console.log("Current URL:", currentUrl);

    //   const model2Pattern = /\/model2\/blob%3Ahttps%3A%2F%2F3d\.silocloud\.io/;
    //   const model3Pattern = /\/model3\/blob%3Ahttps%3A%2F%2F3d\.silocloud\.io/;

    //   if (model2Pattern.test(currentUrl)) {
    //     console.log("Redirecting to https://3d.silocloud.io/");
    //     window.location.href = "https://3d.silocloud.io/";
    //   } else if (model3Pattern.test(currentUrl)) {
    //     console.log("Redirecting to https://3d.silocloud.io/collection");
    //     window.location.href = "https://3d.silocloud.io/collection";
    //   }
    // };

    // handleRedirect();
    // window.addEventListener('pageshow', handleRedirect);

    // return () => {
    //   window.removeEventListener('pageshow', handleRedirect);
    // };
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <TriggerBtnContext.Provider value={{ triggerFetch, toggleFetch }}>
          <ThemeProvider>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/model/:id" element={<ModelViews1 />} />
              <Route path="/model2/:id" element={<ModelViews2 />} />
              <Route path="/model3/:id" element={<ModelViewerget />} />
              <Route path="/collection" element={<Collection />} />
            </Routes>
          </ThemeProvider>
        </TriggerBtnContext.Provider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useCallback, useRef } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import Cookies from "js-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTheme } from "../../contexts/ThemeContext";
import { IoCloudUploadOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import useStorageUsage from "../hooks/useStorageUsage";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../https";
import { uploadThreeDFile } from "../https/upload";
const Upload = () => {
  const [show, setShow] = useState(false);
  const [modelFile, setModelFile] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const abortControllerRef = useRef(null);
  const { theme } = useTheme();
  const [authToken] = useState(Cookies.get("authToken"));
  const { storageDetails } = useStorageUsage();

  const [visibleSection, setVisibleSection] = useState("");

  const handleClose = () => {
    if (uploading) {
      toast.error("Please cancel the upload first.");
      return;
    }
    setModelFile(null);
    setThumbnailFile(null);
    setUploadProgress(0);
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const MAX_MODEL_SIZE_MB = 250;

  const onDrop = useCallback((acceptedFiles) => {
    let hasUnsupportedFile = false;

    acceptedFiles.forEach((file) => {
      const fileSizeInMB = file.size / (1024 * 1024);

      if ([".gltf", ".glb", ".stl"].some((ext) => file.name.endsWith(ext))) {
        if (fileSizeInMB > MAX_MODEL_SIZE_MB) {
          toast.error(
            `Model file is too large. Max size is ${MAX_MODEL_SIZE_MB} MB.`
          );
        } else {
          setModelFile(file);
        }
      } else if (file.type.startsWith("image/")) {
        setThumbnailFile(file);
      } else {
        hasUnsupportedFile = true;
      }
    });

    if (hasUnsupportedFile) {
      toast.error(
        "Unsupported file format. Please upload valid 3D model or image files."
      );
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "model/gltf+json": [".gltf"],
      "model/gltf-binary": [".glb"],
      "model/stl": [".stl"],
      // "model/fbx": [".fbx"],
      // "model/obj": [".obj"],
      "image/*": [],
    },
  });

  const { mutate: uploadThreeD, reset: uploadThreeDFileReset } = useMutation({
    mutationKey: ["upload-threeD"],
    mutationFn: uploadThreeDFile,
    onSuccess: (data) => {
      if (data?.status) {
        console.log("upload", data);
        toast.success(data?.message);
        queryClient.invalidateQueries(["getThreeD"]);
      } else {
        toast.error(data?.message);
      }

      uploadThreeDFileReset();
    },
    onError: (error) => {
      toast.error(`${error.message}`);
    },
  });

  const uploadFiles = async () => {
    if (!modelFile) {
      toast.error("Please Select a Model File");
      return;
    }

    const fileSizeInMB = modelFile.size / (1024 * 1024);

    // Parsing and converting storage values to MB
    const usedStorageInMB =
      parseFloat(storageDetails.usedStorageText) *
      (storageDetails.usedStorageText.includes("GB") ? 1024 : 1);
    // console.log("used Storage In MB : ", usedStorageInMB);

    const totalStorageInMB =
      parseFloat(storageDetails.planStorageText) *
      (storageDetails.planStorageText.includes("GB") ? 1024 : 1);
    // console.log("total Storage In MB", totalStorageInMB);

    const spaceRemaining = totalStorageInMB - usedStorageInMB;

    if (fileSizeInMB > spaceRemaining) {
      toast.error("Storage limit exceeded, please upgrade your storage plan.");
      return;
    } else if (fileSizeInMB > totalStorageInMB) {
      toast.error("Please select a smaller file.");
      return;
    }

    toast.success("Upload will start shortly.");
    setUploading(true);

    const formData = new FormData();
    formData.append("modelFile", modelFile);
    if (thumbnailFile) formData.append("thumbnailFile", thumbnailFile);

    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      "https://api.silocloud.io/api/v1/three-d/upload-threed-file",
      true
    );

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentCompleted = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(percentCompleted);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        toast.success("Upload successful!");
        setUploadProgress(0);
        setUploading(false);
        setShow(false);

        // Update viewer with the new model
        // refreshViewer(); // Call the function to update the viewer

        queryClient.invalidateQueries(["getThreeD"]);
      } else {
        toast.error("Upload failed.");
        setUploadProgress(0);
        setUploading(false);
      }
    };

    xhr.onerror = () => {
      toast.error("Upload failed.");
      setUploadProgress(0);
      setUploading(false);
    };
    xhr.onabort = () => {
      toast.info("Upload canceled.");
      setUploadProgress(0);
      setUploading(false);
    };

    xhr.send(formData);

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    // Optional: Use abortController to cancel the upload if needed
    abortController.signal.addEventListener("abort", () => {
      xhr.abort();
    });

    await uploadThreeD({ modelFile, thumbnailFile });
  };

  const cancelUpload = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setUploading(false); // Stop the uploading state
    setUploadProgress(0); // Reset upload progress
  };

  const removeFile = (setFile) => () => {
    setFile(null);
  };

  const location = useLocation();

  const isCollectionPage2 = location.pathname === "/collection";

  return (
    <div>
      {!authToken ? null : (
        <div
          onClick={handleShow}
          className={`me-1 p-2  ${
            visibleSection === "upload3DModel" ? "active" : ""
          }`}
        >
          <div className="">
            {!isCollectionPage2 ? (
              <IoCloudUploadOutline
                className="fs-2"
                style={{
                  height: "24px",
                  width: "24px",
                }}
              />
            ) : (
              <button
                type="button"
                className={`btn btnCollection d-flex align-items-center viewer-btn
            }`}
              >
                <IoCloudUploadOutline
                  className="fs-4"
                  style={{
                    height: "24px",
                    width: "24px",
                  }}
                />

                <span className="ms-1 align-self-center">Upload</span>
              </button>
            )}
          </div>
        </div>
        // </OverlayTrigger>
      )}
      {show && (
        <div
          className=" show d-block z-3 p-0 m-0 row position-absolute top-50 start-50 translate-middle rounded-4"
          style={{
            backgroundColor: theme === "dark" ? "#2a2b2e" : "#aac0cc",
            color: theme === "dark" ? "#ffffff" : "#364a63",
            width: "85%",
            maxWidth: "500px",
          }}
        >
          <div className="d-flex justify-content-center align-items-center p-4">
            <div className="formbold-form-wrapper">
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <label className="card-title fs-6 fs-md-2 fw-medium">
                    Upload 3D Model and Thumbnail
                  </label>
                  <div
                    onClick={(e) => handleClose(e)}
                    className=" rounded-circle  "
                  >
                    <IoMdClose className="fs-5 text-white me-1 position-absolute closeIcon" />
                  </div>
                </div>
                <div className="mb-6 pt-2">
                  <div
                    {...getRootProps()}
                    className="formbold-mb-5 formbold-file-input"
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <label htmlFor="file">
                        <div>
                          <p className="card-body text-center">
                            Okk Release it...
                          </p>
                        </div>
                      </label>
                    ) : (
                      <label htmlFor="file">
                        <div>
                          <span className="formbold-drop-file roboto-regular ">
                            Drop Files Here
                          </span>
                          <span className="formbold-or fs-9px "> Or </span>
                          <span className="formbold-browse cursor">Browse</span>
                        </div>
                      </label>
                    )}
                  </div>

                  {modelFile ? (
                    <>
                      {" "}
                      <div
                        className={`formbold-file-list formbold-mb-5 ${
                          theme === "dark"
                            ? "slectFileUpload"
                            : "slectFileUpload3"
                        }`}
                      >
                        <div className="formbold-file-item">
                          <span className="formbold-file-name text-truncate">
                            {modelFile
                              ? `Model: ${modelFile.name} (${(
                                  modelFile.size /
                                  (1024 * 1024)
                                ).toFixed(1)} MB)`
                              : null}
                          </span>
                          {modelFile && (
                            <Button
                              variant="danger"
                              onClick={removeFile(setModelFile)}
                              className="ms-2"
                            >
                              <svg
                                width={10}
                                height={10}
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                  fill="currentColor"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </Button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {thumbnailFile ? (
                    <div
                      className={`formbold-file-list formbold-mb-5 ${
                        theme === "dark"
                          ? "slectFileUpload"
                          : "slectFileUpload3"
                      }`}
                    >
                      <div className="formbold-file-item">
                        <span className="formbold-file-name text-truncate">
                          {thumbnailFile
                            ? `Thumbnail: ${thumbnailFile.name} (${(
                                thumbnailFile.size /
                                (1024 * 1024)
                              ).toFixed(1)} MB)`
                            : "No Thumbnail File Selected [optional]"}
                        </span>
                        {thumbnailFile && (
                          <Button
                            variant="danger"
                            onClick={removeFile(setThumbnailFile)}
                            className="ms-2"
                          >
                            <svg
                              width={10}
                              height={10}
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                fill="currentColor"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                fill="currentColor"
                              />
                            </svg>
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {uploadProgress > 0 && (
                    <div
                      className={`formbold-file-list formbold-mb-5 ${
                        theme === "dark"
                          ? "slectFileUpload"
                          : "slectFileUpload3"
                      }`}
                    >
                      <div className="fs-md-4 fs-6 mb-2">Uploading...</div>
                      <ProgressBar
                        now={uploadProgress}
                        label={`${uploadProgress}%`}
                        className="custom-progress-bar"
                      />
                    </div>
                  )}
                </div>

                <div className="w-full justify-items-center m-0">
                  {uploading ? (
                    <Button
                      variant="danger"
                      onClick={cancelUpload}
                      className="btn btn-primary formbold-btn2 w-full"
                    >
                      Cancel Upload
                    </Button>
                  ) : (
                    <button
                      onClick={uploadFiles}
                      className="formbold-btn w-full "
                    >
                      Send File
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={2}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
};

export default Upload;

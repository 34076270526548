import React from "react";
import classNames from "classnames";
import Icon from "../../../assets/Images/applogos2/black_theme/app.png";

const UserAvatar = ({
  className,
  size,
  theme,
  icon,
  text,
  image,
  imageAlt,
  ...props
}) => {
  const classes = classNames({
    "user-avatar": true,
    [`${className}`]: className,
    [`user-avatar-${size}`]: size,
    [`bg-${theme}`]: theme,
  });
  return (
    <div className={classes}>
      {icon ? <Icon name={icon} /> : null}
      {image && (
        <img
          src={image}
          alt={imageAlt}
          className="rounded-circle"
          style={{ width: "40px" }}
        />
      )}
      {text && !image && <span>{text}</span>}
      {props.children}
    </div>
  );
};

export default UserAvatar;

import React, { useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Hero from "../components/Home/Hero.jsx";
import { useTheme } from "../contexts/ThemeContext";
import Meshes from "../components/Features/Meshes.jsx";
import Details from "../components/Features/Details.jsx";
import Settings from "../components/Features/Settings.jsx";
import Materials from "../components/Features/Materials.jsx";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { RxCube } from "react-icons/rx";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { PiCubeFocusLight } from "react-icons/pi";
import axios from "axios";
import Cookies from "js-cookie";
import logoR from "../assets/Images/3dviewer_net_logo.png";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

window.onload = function () {
  const currentUrl = window.location.href;

  if (currentUrl.includes("/model2/")) {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        window.location.href = "https://3d.silocloud.io/";
      }
    }
  } else if (currentUrl.includes("/model3/")) {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        window.location.href = "https://3d.silocloud.io/collection";
      }
    }
  }
};

const ModelViews2 = () => {
  const { id } = useParams();
  const authToken = Cookies.get("authToken");
  const [modelInfo, setModelInfo] = useState({
    vertices: 0,
    triangles: 0,
    sizeX: 0,
    sizeY: 0,
    sizeZ: 0,
    volume: 0,
    surfaceArea: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [modelParts, setModelParts] = useState([]);
  const [color1, setColor1] = useState("#ffffff");
  const [materialInfo, setMaterialInfo] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [selectedPartId, setSelectedPartId] = useState(null);
  const [selectedPart, setSelectedPart] = useState({ id: null, name: null });
  const [visibleSection, setVisibleSection] = useState("meshes");
  const { theme } = useTheme();
  const clickTimeoutRef = useRef(null);

  const mountRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());

  window.onload = function () {
    const currentUrl = window.location.href;
    console.log("window.performance:", window.performance);
    if (currentUrl.includes("/model3/")) {
      if (window.performance) {
        if (performance.navigation.type === 1) {
          window.location.href = "https://3d.silocloud.io/collection";
        } else {
          console.log("Not directed");
        }
      }
    }
  };

  const scene = sceneRef.current;
  const renderer = new THREE.WebGLRenderer({ antialias: true });

  if (theme === "dark") {
    scene.background = new THREE.Color(0x212529);
  } else if (backgroundColor != null) {
    scene.background = new THREE.Color(backgroundColor);
  } else {
    scene.background = new THREE.Color(0xf8f9fa);
  }
  const camera = useRef(
    new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.25,
      50
    )
  ).current;
  const controls = new OrbitControls(camera, renderer.domElement);

  useEffect(() => {
    controls.enableZoom = true;
    controls.zoomSpeed = 1.0;
    controls.maxPolarAngle = Math.PI;
  }, [controls]);

  useEffect(() => {
    const currentMount = mountRef.current;
    renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
    renderer.physicallyCorrectLights = true;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    currentMount.appendChild(renderer.domElement);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.add(new THREE.Vector3(0, 1, 0));
    scene.add(directionalLight);

    // Additional lights for scene setup
    const keyLight = new THREE.DirectionalLight(0xffffff, 0.5);
    keyLight.position.set(10, 10, 10).normalize();
    scene.add(keyLight);

    const fillLight = new THREE.DirectionalLight(0xffffff, 0.5);
    fillLight.position.set(-5, 10, 10).normalize();
    scene.add(fillLight);

    const backLight = new THREE.DirectionalLight(0xffffff, 0.5);
    backLight.position.set(-10, -10, -10).normalize();
    scene.add(backLight);

    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.6);
    scene.add(hemisphereLight);

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    const onSingleClick = (event) => {
      // console.log("Double Click");

      setModelParts((prevParts) =>
        prevParts.map((part) => {
          if (part.selected) {
            part.node.material = part.originalMaterial;
          }
          return part;
        })
      );
    };

    const onMouseClick = (event) => {
      // console.log("Single Click");

      // Calculate mouse position relative to the canvas
      const canvasBounds = renderer.domElement.getBoundingClientRect();
      const mouseX =
        ((event.clientX - canvasBounds.left) / canvasBounds.width) * 2 - 1;
      const mouseY =
        -((event.clientY - canvasBounds.top) / canvasBounds.height) * 2 + 1;

      // Perform picking based on mouse coordinates
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(new THREE.Vector2(mouseX, mouseY), camera);

      // Check intersections with model parts
      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const intersectedNode = intersects[0].object;
        const material = intersectedNode.material;
        setMaterialInfo(material);

        setModelParts((prevParts) =>
          prevParts.map((part) => {
            if (part.node === intersectedNode) {
              const selectedMaterial = new THREE.MeshBasicMaterial({
                color: 0x3393bd, // Light blue color for highlight
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 0.5,
              });
              part.node.material = selectedMaterial;

              return { ...part, selected: true };
            } else {
              if (part.selected) {
                part.node.material = part.originalMaterial;
              }
              return { ...part, selected: false };
            }
          })
        );

        setSelectedPart({
          id: intersectedNode.uuid,
          name: intersectedNode.name || intersectedNode.uuid,
        });
      }
    };

    // Handle single and double click with a timeout
    const handleClick = (event) => {
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
        clickTimeoutRef.current = null;
        onSingleClick(event); // Handle double-click logic
      } else {
        clickTimeoutRef.current = setTimeout(() => {
          onMouseClick(event); // Handle single-click logic
          clickTimeoutRef.current = null;
        }, 250); // Adjust delay as needed
      }
    };

    // Update canvas size on window resize
    const handleResize = () => {
      renderer.setSize(
        mountRef.current.clientWidth,
        mountRef.current.clientHeight
      );
      camera.aspect =
        mountRef.current.clientWidth / mountRef.current.clientHeight;
      camera.updateProjectionMatrix();
    };

    // Handle WebGL context lost/restored events
    const handleContextLost = (event) => {
      event.preventDefault();
      console.warn("WebGL context lost");
    };

    const handleContextRestored = () => {
      console.log("WebGL context restored");
      // Reinitialize renderer or reload model if necessary
    };

    renderer.domElement.addEventListener(
      "webglcontextlost",
      function (event) {
        event.preventDefault(); // Prevent the default behavior of losing the context permanently
        console.warn("WebGL context lost, attempting to restore...");
      },
      false
    );

    renderer.domElement.addEventListener(
      "webglcontextrestored",
      function () {
        console.log("WebGL context restored.");
        // You might need to reinitialize the WebGL context and re-render the scene here.
        animate();
      },
      false
    );

    const handleDrop = (event) => {
      event.preventDefault();
      const dropFile = event.dataTransfer.files[0];
      if (dropFile) {
        const fileUrl = URL.createObjectURL(dropFile);
        const extension = dropFile.name.split(".").pop();

        localStorage.setItem("fileExtension", extension);

        Cookies.set("fileExtension", extension, { expires: 5 / 1440 });

        // Navigate to the model URL
        Navigate(`/model2/${encodeURIComponent(fileUrl)}`);
      }
    };

    const handleDragOver = (event) => {
      event.preventDefault();
    };

    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", handleDrop);

    window.addEventListener("click", handleClick);
    window.addEventListener("resize", handleResize);
    return () => {
      currentMount.removeChild(renderer.domElement);
      renderer.domElement.removeEventListener(
        "webglcontextlost",
        handleContextLost,
        false
      );
      renderer.domElement.removeEventListener(
        "webglcontextrestored",
        handleContextRestored,
        false
      );
      
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", handleDrop);
      window.removeEventListener("click", handleClick); 
      window.removeEventListener("resize", handleResize);
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, [scene, camera]);

  const fetchDataWithDebounce = debounce(async () => {
    try {
      const response = await axios.post(
        "https://api.silocloud.io/api/v1/three-d/get-threed-file",
        { three_d_id: id },
        {
          headers: {
            authToken: authToken,
            "Content-Type": "application/json",
          },
        }
      );

      const fileFormat = response.data.data.three_d.file_format;
      const modelUrl = response.data.data.three_d.file_path;

      if (response.data) {
        loadModel(modelUrl, fileFormat);
      } else {
        console.error("Failed to fetch model data:", response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching model:", error);
      setIsLoading(false);
    }
  }, 300);

  useEffect(() => {
    fetchDataWithDebounce();
  }, [id, authToken]);

  const loadModel = (modelUrl, fileFormat) => {
    if (!modelUrl || !fileFormat) {
      console.error("No fileFormat, modelUrl found.");
      return;
    }

    let loader;
    switch (fileFormat) {
      case "gltf":
      case "glb":
        loader = new GLTFLoader();
        loader = new GLTFLoader();
        // Add DracoLoader to GLTFLoader
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath(
          "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
        );
        loader.setDRACOLoader(dracoLoader);
        break;
      case "stl":
        loader = new STLLoader();
        break;
      default:
        console.error("Unsupported file format");
        return;
    }

    loader.load(
      modelUrl,
      (loadedModel) => {
        let model;
        if (fileFormat === "stl") {
          const material = new THREE.MeshStandardMaterial({ color: 0xc2c2c2 });
          model = new THREE.Mesh(loadedModel, material);
        } else {
          model = loadedModel.scene || loadedModel;
        }

        const parts = [];
        let vertices = 0;
        let triangles = 0;
        const box = new THREE.Box3().setFromObject(model);
        const size = box.getSize(new THREE.Vector3());
        let volume = 0;
        let surfaceArea = 0;

        model.traverse((node) => {
          if (node.isMesh) {
            node.castShadow = true;
            node.receiveShadow = true;

            const originalMaterial = node.material.clone();
            parts.push({
              id: node.uuid,
              name: node.name || node.uuid,
              visible: true,
              selected: false,
              node,
              originalMaterial,
            });

            const geometry = node.geometry;
            vertices += geometry.attributes.position.count;

            if (geometry.index !== null) {
              triangles += geometry.index.count / 3;
            } else {
              triangles += geometry.attributes.position.count / 3;
            }
          }
        });

        setModelParts(parts);
        setModelInfo({
          vertices,
          triangles,
          sizeX: size.x,
          sizeY: size.y,
          sizeZ: size.z,
          volume,
          surfaceArea,
        });

        sceneRef.current.add(model);

        const boxCenter = box.getCenter(new THREE.Vector3());
        const maxDim = Math.max(size.x, size.y, size.z);

        // Adjust camera distance based on the size of the model
        const fov = camera.fov * (Math.PI / 180);
        const cameraZ = Math.abs(maxDim / Math.sin(fov / 2)) * 1.5;

        camera.position.set(boxCenter.x, boxCenter.y, cameraZ);
        camera.lookAt(boxCenter);

        controls.target.copy(boxCenter);
        controls.maxDistance = maxDim * 10;
        camera.near = maxDim / 100;
        camera.far = maxDim * 100;
        camera.updateProjectionMatrix();
        controls.update();

        setIsLoading(false);
      },
      undefined,
      (error) => {
        console.error("Error loading model:", error);
      }
    );
  };

  useEffect(() => {
    scene.background = new THREE.Color(color1);
  }, [color1]);

  const calculateVolume = (geometry) => {
    // Volume calculation logic
    return 0; // Placeholder
  };

  const calculateSurfaceArea = (geometry) => {
    // Surface area calculation logic
    return 0; // Placeholder
  };
  const toggleVisibility = (partId, e) => {
    e.stopPropagation();
    setModelParts((prevParts) => {
      return prevParts.map((part) => {
        if (part.id === partId) {
          part.node.visible = !part.node.visible;
          return { ...part, visible: part.node.visible };
        }
        return part;
      });
    });
  };

  const selectPart = (partId, partName) => {
    setModelParts((prevParts) => {
      const updatedParts = prevParts.map((part) => {
        if (part.id === partId) {
          const isSelected = !part.selected;

          if (isSelected) {
            const selectedMaterial = new THREE.MeshBasicMaterial({
              color: 0x3393bd,
              side: THREE.DoubleSide,
              transparent: true,
              opacity: 0.5,
            });
            part.node.material = selectedMaterial;
          } else {
            part.node.material = part.originalMaterial;
          }

          return { ...part, selected: isSelected };
        }
        part.node.material = part.originalMaterial;
        return { ...part, selected: false };
      });

      return updatedParts;
    });

    setSelectedPart({ id: partId, name: partName });
  };

  return (
    <div className="bg-white dark:bg-navy-900 h-full">
      <Hero />
      <div className={`other-component asdfghjkl pt-1 ${theme}`}>
        <div className="LoaderMain">
          {isLoading && (
            <div
              className="d-flex justify-content-center align-items-center shadow loaderBackground position-absolute  text-center p-2  w-50 h-50"
              style={{
                backgroundColor: theme === "dark" ? "#2A2B2E" : "#d3d3d3",
              }}
            >
              <div className="text-center ">
                <div className="Customloader mb-3">
                  <img src={logoR} alt="Loading..." />
                </div>
                <div className="fs-2 pt-2 text-white">Loading Model...</div>
              </div>
            </div>
          )}

          <div className="row px-1">
            <div className="col-4 d-none d-md-block col-lg-3">
              <div className="row">
                <div className="col-xl-2 col-md-3 border-end border-dark-subtle text-center justify-content-center">
                  <ul className="nav nav-pills nav-flush mx-1">
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-right-1">Meshes</Tooltip>}
                    >
                      <div
                        className={`SecondNavButton3 ${
                          visibleSection === "meshes" ? "active" : ""
                        }`}
                        onClick={() => setVisibleSection("meshes")}
                      >
                        <div>
                          <RxCube className="fs-1 p-1 m-1" />
                        </div>
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-right-2">Details</Tooltip>}
                    >
                      <div
                        className={`mt-1 SecondNavButton3 ${
                          visibleSection === "details" ? "active" : ""
                        }`}
                        onClick={() => setVisibleSection("details")}
                      >
                        <div>
                          <HiOutlineClipboardDocumentList className="fs-1 p-1 m-1" />
                        </div>
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-right-3">Settings</Tooltip>}
                    >
                      <div
                        className={`mt-1 SecondNavButton3 ${
                          visibleSection === "settings" ? "active" : ""
                        }`}
                        onClick={() => setVisibleSection("settings")}
                      >
                        <div>
                          <IoSettingsOutline className="fs-1 p-1 m-1" />
                        </div>
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-right-4">Materials</Tooltip>
                      }
                    >
                      <div
                        className={`mt-1 SecondNavButton3 ${
                          visibleSection === "materials" ? "active" : ""
                        }`}
                        onClick={() => setVisibleSection("materials")}
                      >
                        <div>
                          <PiCubeFocusLight className="fs-1 p-1 m-1" />
                        </div>
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-right-5">Files</Tooltip>}
                    >
                      <div
                        className={`mt-1 SecondNavButton3 ${
                          visibleSection === "files" ? "active" : ""
                        }`}
                        onClick={() => setVisibleSection("files")}
                      >
                        <div>{/* Add an icon or content here if needed */}</div>
                      </div>
                    </OverlayTrigger>
                  </ul>
                </div>
                <div className="col-xl-10  col-md-9 ">
                  <div className="scrollable-column overflow-x-hidden">
                    <div>
                      {visibleSection === "meshes" && (
                        <Meshes
                          props={{
                            modelParts: modelParts,
                            selectedPartId: selectedPartId,
                            selectPart: selectPart,
                            selectedPart: selectedPart,
                            toggleVisibility: toggleVisibility,
                            theme: theme,
                          }}
                        />
                      )}
                      {visibleSection === "details" && (
                        <Details modelInfo={modelInfo} />
                      )}
                      {visibleSection === "settings" && (
                        <Settings color1={color1} setColor1={setColor1} />
                      )}
                      {visibleSection === "materials" && (
                        <Materials material={materialInfo} />
                      )}
                      {/* {visibleSection === "files" && <File/>} */}
                      {/* {visibleSection === "files" && <File files={files}/>} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-12 col-md-8 col-lg-9 p-0 ">
              <div
                style={{
                  height: "calc(100vh - 130px)",
                  width: "100%",
                  border: "1px solid #7f7f7f",
                }}
              >
                <div
                  ref={mountRef}
                  id="ModelLoding"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelViews2;

import React from "react";
import Hero from "../components/Home/Hero.jsx";
import CollectionPage from "../components/Collection/CollectionPage2.jsx";
import Footer from "../components/Home/Footer/Footer.jsx";

const Collection = () => {
  return (
    <div>
      <Hero/>
      <CollectionPage/>
      <Footer/>
     
    </div>
  );
};

export default Collection;

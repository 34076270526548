import React, { useState } from "react";
import {
  IoReorderThreeOutline,
  IoVideocam,
  IoMicOutline,
} from "react-icons/io5";
import { BiSolidPhone } from "react-icons/bi";
import { useTheme } from "../../../contexts/ThemeContext";
import { Tooltip } from "reactstrap";

const TalkComponent = () => {
  const { theme } = useTheme();
  const [tooltipOpen, setTooltipOpen] = useState({
    menu: false,
    video: false,
    mic: false,
    call: false,
  });

  // Function to toggle tooltip visibility
  const toggleTooltip = (key) => {
    setTooltipOpen({
      ...tooltipOpen,
      [key]: !tooltipOpen[key],
    });
  };
  return (
    <>
      <div className="d-none d-lg-flex d-flex align-items-center footer-icons justify-content-center justify-content-lg-end mb-2">
        <span
          id="TooltipMenu"
          className="icon "
          style={{
            backgroundColor: theme === "dark" ? "#4442499e  " : "#ededed7a",
            color: theme === "dark" ? "#fff" : "#526484",
          }}
        >
          <IoReorderThreeOutline style={{ height: "15px", width: "15px" }} />
          <Tooltip
            isOpen={tooltipOpen.menu}
            target="TooltipMenu"
            toggle={() => toggleTooltip("menu")}
          >
            Contacts
          </Tooltip>
        </span>

        <span
          id="TooltipVideo"
          className="icon "
          style={{
            backgroundColor: theme === "dark" ? "#4442499e" : "#ededed7a",
            color: theme === "dark" ? "#fff" : "#526484",
          }}
        >
          <IoVideocam style={{ height: "15px", width: "15px" }} />
          <Tooltip
            isOpen={tooltipOpen.video}
            target="TooltipVideo"
            toggle={() => toggleTooltip("video")}
          >
            Video Call
          </Tooltip>
        </span>

        <span
          id="TooltipMic"
          className="icon "
          style={{
            backgroundColor: theme === "dark" ? "#4442499e" : "#ededed7a",
            color: theme === "dark" ? "#fff" : "#526484",
          }}
        >
          <IoMicOutline style={{ height: "15px", width: "15px" }} />
          <Tooltip
            isOpen={tooltipOpen.mic}
            target="TooltipMic"
            toggle={() => toggleTooltip("mic")}
          >
            Mute
          </Tooltip>
        </span>
        <span
          id="TooltipCall"
          className="end-call icon"
          style={{
            backgroundColor: theme === "dark" ? "#ed0607" : "#ed0607",
            color: theme === "dark" ? "#fff" : "#fff",
          }}
        >
          <BiSolidPhone style={{ height: "15px", width: "15px" }} />
          <Tooltip
            isOpen={tooltipOpen.call}
            target="TooltipCall"
            toggle={() => toggleTooltip("call")}
          >
            End
          </Tooltip>
        </span>
      </div>

      {/* <div className="d-flex align-items-center footer-icons justify-content-center justify-content-lg-end mb-2">
        <span
          className="icon "
          style={{
            backgroundColor: theme === "dark" ? "#4442499e  " : "#ededed7a",
            color: theme === "dark" ? "#fff" : "#526484",
          }}
        >
          <IoReorderThreeOutline  style={{height: "15px", width:"15px"}} />
        </span>
        <span
          className="icon "
          style={{
            backgroundColor: theme === "dark" ? "#4442499e" : "#ededed7a",
            color: theme === "dark" ? "#fff" : "#526484",
          }}
        >
          <IoVideocam  style={{height: "15px", width:"15px"}}/>
        </span>
        <span
          className="icon "
          style={{
            backgroundColor: theme === "dark" ? "#4442499e" : "#ededed7a",
            color: theme === "dark" ? "#fff" : "#526484",
          }}
        >
          <IoMicOutline  style={{height: "15px", width:"15px"}}/>
        </span>
        <span
          className="icon "
          style={{
            backgroundColor: theme === "dark" ? "#ed0607" : "#ed0607",
            color: theme === "dark" ? "#fff" : "#fff",
          }}
        >
          <BiSolidPhone style={{height: "15px", width:"15px"}} />
        </span>
      </div> */}
    </>
  );
};

export default TalkComponent;

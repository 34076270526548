import React, { useEffect, useState } from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import { dark_calendar } from "../../../assets/Images/applogos2";
const Calender = () => {
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const { theme } = useTheme();

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const day = days[now.getDay()];
      const date = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();

      setDate(`${day}, ${date}/${month}/${year}`);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="d-none d-sm-flex">
      <div className="pe-3">
        <div className="roboto-medium" style={{ color: "#08aeff" }}>
          {time}
        </div>
        <div
          className="fs-9px"
          style={{ color: theme === "dark" ? "#f5f9ff" : "#526484" }}
        >
          {date}
        </div>
      </div>

      <a href="https://calendar.silocloud.io/">
        <img src={dark_calendar} alt="calender" className="head-icon" />
      </a>
    </div>
  );
};

export default Calender;

import React, { useEffect, useState, forwardRef } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import logo3d from "../../assets/Images/3D Viewer.png";
import logo3d1 from "../../assets/Images/3D Viewer B.png";
import "./Home.css";
import SelectFromfile from "./SelectFromfile";
import Upload3Dmodel2 from "./Upload3Dmodel2.jsx";
import Upload3Dmodel from "./Upload3Dmodel.jsx";
import { Navbar } from "react-bootstrap";
import Header from "./Header/Header.jsx";
import { FaRegFolder } from "react-icons/fa6";
import Calender from "./Header/Calender.jsx";
import { useLocation, Link } from "react-router-dom";
import Cookies from "js-cookie";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Hero = () => {
  const { theme } = useTheme();
  const location = useLocation();
  const isCollectionPage = location.pathname === "/collection";
  const isHomePage = location.pathname === "/";
  const logoSrc = theme === "dark" ? logo3d1 : logo3d;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const authToken = Cookies.get("authToken");
    setIsLoggedIn(!!authToken);
  }, []);

  const handleShow = () => {
    // console.log("Show Upload 3D Model");
  };

  const UploadIcon = forwardRef(({ onClick }, ref) => {
    const { theme } = useTheme();
    return (
      <div ref={ref} onClick={onClick}>
        <Upload3Dmodel2 />
      </div>
    );
  });

  return (
    <div className={`hero ${theme}`}>
      <div className="d-flex justify-content-between align-items-center px-2">
        <a href="https://3d.silocloud.io/">
          <img src={logoSrc} alt="Logo" className="LogoNav" />
        </a>
        <Calender />
        <Header />
      </div>

      <div
        className="d-flex justify-content-end align-items-center px-2"
        style={{
          backgroundColor: theme === "dark" ? "#2A2B2E" : "#FFFFFF",
          height: "44px",
        }}
      >
        {/* <div className="d-flex">
          {isHomePage && (
            <>
             <SelectFromfile /> 
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-top">Upload 3D Model</Tooltip>}
              >
                <UploadIcon onClick={handleShow} />
              </OverlayTrigger>
            </>
          )}
        </div> */}

        <div
          className="d-flex justify-content-between"
          style={{ padding: "1px" }}
        >
          {!isLoggedIn ? null : (
            <>
              {isCollectionPage ? (
                <Upload3Dmodel />
              ) : (
                // null
                <Link
                  to="/collection"
                  className=" d-flex  py-2 align-content-center"
                >
                  <button
                    type="button"
                    className="btn btnCollection d-flex align-items-center "
                  >
                    <FaRegFolder
                      className="fs-2 "
                      style={{
                        height: "24px",
                        width: "24px",
                      }}
                    />
                    <span className="ps-1 align-self-center">
                      Go To Collections
                    </span>
                  </button>
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React, { useEffect, useRef, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Dropdown, DropdownToggle, DropdownMenu, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { HiBell } from "react-icons/hi2";
import {
  getAllNotificationHandler,
  setNotificationCountHandler,
} from "../../../utils/api_helper.jsx";
import { FiCornerDownRight, FiCornerDownLeft } from "react-icons/fi";
import { formatDateTime } from "../../../utils/utils";
import { useTheme } from "../../../contexts/ThemeContext";
import "./Header.css";
import "./Notification.css";
import { queryClient } from "../../https";
const Notifi = () => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { theme } = useTheme();

  const toggle = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function NotificationItem({ title, isSender, updatedAt, notification }) {
    const [isOpen, setIsOpen] = useState(false);
    const EventToggle = () => setIsOpen(!isOpen);
    const Icon = isSender ? FiCornerDownRight : FiCornerDownLeft;
    const iconName = isSender ? "curve-down-right" : "curve-down-left";
    const bgColor = isSender ? "text-danger" : "text-success";

    const handleModuleNotifications = (notification) => {
      switch (notification.module) {
        case "6":
          setIsOpen(true);
          console.log(notification.reference_id);
          break;
        default:
          console.log(`Component Not Available!...`);
          break;
      }
    };

    return (
      <>
        <li
          className={` p-3 ${theme ==="dark" ?"web-app":"web-app2"}`}
          onClick={() => {
            handleModuleNotifications(notification);
          }}
        >
          <div className={` justify-content-between align-items-start w-100 `} >
            <div className="d-flex align-items-center gap-1 gap-md-2">
              <div
                className="nk-notification-icon icon-circle"
                style={{
                  backgroundColor: theme === "dark" ? "#202a47" : "#fcedee",
                }}
              >
                <Icon name={iconName} className={` ${bgColor}`} />
              </div>
              <div className="nk-notification-content">
                <div
                  className="nk-notification-text roboto-large "
                  style={{
                    color: theme === "dark" ? "#b6c6e3" : "#526484"
                  }}
                >
                  {title}
                </div>
                <div
                  className=" nk-notification-time"
                  style={{ fontWeight: "400" }}
                >
                  {formatDateTime(updatedAt)}
                </div>
              </div>
            </div>
          </div>
        </li>
      </>
    );
  }

  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState({
    notifications: [],
  });
  const [limit, setLimit] = useState(10);
  const [notificationIds, setNotificationIds] = useState([]);

  // Fetch notifications
  const {
    data: allNotificationData,
    isLoading: allNotificationIsLoading,
    isRefetching: allNotificationIsRefetching,
    isError: allNotificationIsError,
    error: allNotificationError,
    refetch: allNotificationRefetch,
  } = useQuery({
    queryKey: ["get-all-notification", limit],
    queryFn: async () => getAllNotificationHandler({ limit: limit }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (allNotificationIsError) {
      console.log(allNotificationError);
    }
  }, [allNotificationIsError, allNotificationError]);

  // let notificationLength;
  // if (notificationLength) {
  //   notificationLength =
  //   allNotificationData === undefined
  //       ? " "
  //       : Object.keys(allNotificationData.data).length !== 0 && allNotificationData.data
  //       ? allNotificationData?.length
  //       : 0;
  // }

  // Mark all notifications as read mutation
  const { isPending: markAllReadIsPending, mutate: markAllReadMutate } =
    useMutation({
      mutationKey: ["mark-all-as-read"],
      mutationFn: setNotificationCountHandler,
      onSuccess: (data) => {
        if (data.toast) {
          if (!data.status) {
            toast.error(data?.message);
            if (data?.redirect) {
              console.log(process.env.REACT_APP_ACCOUNT_LOGIN_URL);
            }
          } else {
            // allNotificationRefetch()
            toast.success(data?.message);
            queryClient.invalidateQueries(["get-all-notification", "getThreeD"]);

          }
        }
      },
    });

  useEffect(() => {
    if (!allNotificationIsLoading) {
      if (allNotificationData || allNotificationData.status) {
        const unReadNotifications = Array.isArray(
          allNotificationData.data?.notifications
        )
          ? allNotificationData.data.notifications.filter(
              (notification) => notification.seen === "0"
            )
          : [];
        setNotificationCount(unReadNotifications.length || 0);
        let newNotifications;
        if(Object.keys(allNotificationData.data).length !== 0) {
           newNotifications = allNotificationData.data.notifications.filter(
            (notification) => {
              return !notificationData.notifications.some(
                (existingNotification) =>
                  existingNotification.id === notification.id
              );
            }
          );
          setNotificationData((prevNotification) => ({
            notifications: [
              ...prevNotification.notifications,
              ...newNotifications,
            ],
          }));
        }
        

        
      } else if (allNotificationData && allNotificationData.redirect) {
      }
    }

    // eslint-disable-next-line
  }, [allNotificationData, allNotificationIsLoading]);

  useEffect(() => {
    if (!allNotificationIsLoading && allNotificationData) {
      if (notificationData?.status) {
        const notificationsId = allNotificationData?.data?.notifications.map(
          (notification) => notification.id
        );
        setNotificationIds(notificationsId);
      }
    }

    // eslint-disable-next-line
  }, [allNotificationData, allNotificationIsLoading]);

  const loadMoreNotifications = async () => {
    setLimit((prevLimit) => prevLimit + 10);
  };

  return (
    <Dropdown
      isOpen={open}
      toggle={toggle}
      className={`user-dropdown hero ${theme}`}
    >
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div
          className={`rounded-circle p-2  align-items-center ${
            open
              ? theme === "dark"
                ? "activeApps2"
                : "activeApps"
              : theme === "dark"
              ? "appicons2"
              : "appicons"
          }`}
        >
          <HiBell
            name="bell-fill "
            className="fs-3"
            style={{
              color: "#8094ae",
            }}
          />
          {notificationCount > 0 && (
            <div color="primary" className="countIcon">
              {allNotificationIsLoading ? 0 : notificationCount}
            </div>
          )}
        </div>
      </DropdownToggle>

      <DropdownMenu
        end
        className=" dropdown-menu rounded-top notifimenu notifibox  p-0 shadow"
        style={{
          backgroundColor: theme === "dark" ? "#1B1B1B" : "#FEFFFE  ",
          color: theme === "dark" ? "#B6C6E3" : "#526284"
        }}
      >
        {/* Header */}
        <div className=" d-flex justify-content-between align-items-center  noti_title rounded-top  ">
          <div className="noti_font">Notifications</div>
          <div
            size="small"
            className="noti_font"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (notificationCount <= 0) {
                toast.error("There are no unread messages.");
              } else {
                markAllReadMutate({ notification_id: 0, status: 1 });
              }
            }}
          >
            {markAllReadIsPending ? (
              <Spinner
                size={"sm"}
                color="primary"
                style={{ borderWidth: "1px" }}
              />
            ) : (
              "Mark All as Read"
            )}
          </div>
        </div>

        {/* Body */}
        <div className="dropdown-body">
          <div className="nk-notification dropdownMenu">
            {allNotificationIsLoading ? (
              <div className=" center noti_font d-flex justify-content-center align-items-center noti_title  border-top  ">
                Loading...
              </div>
            ) : allNotificationData?.code === 500 ? (
              <div
                className="center noti_font d-flex justify-content-center align-items-center noti_title  border-top"
                style={{
                  padding: "1.25rem",
                  color: "rgb(225, 73, 84)",
                  fontWeight: "500",
                  textTransform: "capitalize",
                }}
              >
                {allNotificationData?.message}
              </div>
            ) : notificationData.notifications.length > 0 ? (
              <div className=" border-top">
                {notificationData.notifications.map((notification, index) => (
                  <NotificationItem
                    key={index}
                    title={notification.title}
                    time={moment(notification.created_at).format("DD MMM")}
                    isSender={notification.is_sender}
                    link={notification.link}
                    updatedAt={notification.created_at}
                    recent={notification.recent}
                    notification={notification}
                  />
                ))}
                {/* View More button */}
                {!allNotificationIsRefetching && (
                  <div className=" center noti_font d-flex justify-content-center align-items-center noti_title  border-top  ">
                    <div
                      className="pointer"
                      onClick={loadMoreNotifications}
                      disabled={allNotificationIsRefetching}
                    >
                      {allNotificationIsRefetching ? (
                        <Spinner
                          size="sm"
                          style={{ borderWidth: "1px", color: "inherit" }}
                        />
                      ) : (
                        "View More"
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className=" center noti_font d-flex justify-content-center align-items-center noti_title  border-top  ">
                No Notification Found
              </div>
            )}
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notifi;

import React, { useEffect, useState } from "react";
import Apps from "./Apps";
import "./Header.css";
import Notifi from "./Notifi";
import User from "./User";
import { GoScreenFull } from "react-icons/go";
import { BsFullscreenExit } from "react-icons/bs";
import Cookies from "js-cookie";
const Header = () => {
  const [fullScreenOn, setFullScreenOn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleFullScreen = () => {
    setFullScreenOn(!fullScreenOn);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // Enter fullscreen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };
  useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  return (
    <div className="d-flex ">
      <div className="align-items-center d-flex text-decoration-none align-content-center">
        <div
          className="cursor rounded-circle me-1 e"
          style={{
            color: "#8094ae",
          }}
        >
          {fullScreenOn ? (
            <span className="rounded-circle p-2" onClick={toggleFullScreen}>
              <BsFullscreenExit className="  fs-5 fw-bold" />
            </span>
          ) : (
            <span className="rounded-circle p-2" onClick={toggleFullScreen}>
              <GoScreenFull className="  fs-5 fw-bold" />
            </span>
          )}
        </div>
        {isLoggedIn ? (
          <div className="ms-1">
            <Notifi />
          </div>
        ) : null}
        <div className="mx-2">
          <Apps />
        </div>
        <div className="  me-2 p-1">
          <User />
        </div>
      </div>
    </div>
  );
};

export default Header;

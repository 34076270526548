import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Hero from "../components/Home/Hero.jsx";
import Main from "../components/Home/Main.jsx";
import Footer from "../components/Home/Footer/Footer.jsx";

const Homepage = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleSubmit = () => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      navigate(`/model2/${encodeURIComponent(fileUrl)}`);
    }
  };

  return (
    <div>
      <Hero onFileChange={handleFileChange} onSubmit={handleSubmit} />
      <Main onFileChange={handleFileChange} onSubmit={handleSubmit} />
      <Footer/>
    </div>
  );
};

export default Homepage;

import axios from "axios";
import { getCookie } from "../../utils/utils";

export const getCollection = async () => {

    const data = await axios({
        method: "get",
        url: "https://api.silocloud.io/api/v1/three-d/get-all-threed-file",
        
            headers: {
                'authToken': getCookie("authToken", null),
            }
        
      })

    return data;
};



export const getCookie = (name, defaultValue = "") => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return defaultValue;
};


export function shortenString(str, maxLen) {
    if (str.length <= maxLen) {
      return str;
    } else {
      return str.slice(0, maxLen - 1) + "...";
    }
  }

export function formatDateTime(timestamp) {
  const date = new Date(timestamp);
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
  const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
  const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
  return `${formattedDate} at ${formattedTime}`;
}



import React, { useRef, useState } from "react";
import Cookies from "js-cookie";
import { useTheme } from "../../contexts/ThemeContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { ImInfo } from "react-icons/im";
import { Tooltip } from "reactstrap";

const InnerModel = () => {
  const { theme } = useTheme();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleIconClick = () => {
    fileInputRef.current.click(); // Trigger the file input click event
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile);
      const extension = selectedFile.name.split(".").pop();

      // Store the extension in local storage
      localStorage.setItem("fileExtension", extension);
      // console.log("Local Storage:", localStorage.getItem("fileExtension")); // Debugging

      // Set the cookie with the file extension, expires in 10 minutes
      Cookies.set("fileExtension", extension, { expires: 5 / 1440 });
      // console.log("Cookie:", Cookies.get("fileExtension")); // Debugging

      // Navigate to the model URL
      navigate(`/model2/${encodeURIComponent(fileUrl)}`);
    }
  };

  return (
    <div className={`hero ${theme}`}>
      <div className="online-3d-viewer top-25">
        <div className="text-center fs-5 fs-sm-3 fw-medium p-1">
          Drag and drop 3D models here.
        </div>
        <div className="fs-5 p-2">or</div>
        <div className="d-flex justify-content-center align-content-center gap-2">
        <div className="d-flex align-items-center gap-3 p-2">
          <div
            className="btn btn-outline-danger"
            style={{ borderRadius: "4px" }}
            onClick={handleIconClick}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none", borderRadius: "4px" }}
              onChange={handleFileChange}
              accept=".gltf,.glb,.stl,.fbx,.obj"
            />
            <label htmlFor="file-input" className="pointer">
              <svg
                className="me-2"
                viewBox="0 0 20 17"
                style={{ height: "24px", width: "24px" }}
              >
                <path
                  d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                  fill="#e14954"
                />
              </svg>

              <span className="fs-5">Choose file</span>
            </label>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <ImInfo style={{ height: "18px", width: "18px" }} id="ImInfo" />
          <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target="ImInfo"
            toggle={toggle}
            style={{
              backgroundColor: theme === "dark" ? "#2a2b2e" : "#efefef85",
              color: theme === "dark" ? "#fffff" : "#000000",
            }}
          >
            <div className="roboto-medium fw-medium border-bottom py-2">
              Supported 3D Files
            </div>
            <div className="row px-2">
              <div className="col-6 roboto-regular p-1">.glb</div>
              <div className="col-6 roboto-regular p-1">.gltf</div>
              <div className="col-6 roboto-regular p-1">.stl</div>
              {/* <div className="col-6 roboto-regular p-1">.obj</div> */}
              {/* <div className="col-6 roboto-regular p-1">.fbx</div> */}
            </div>
            <div className="border-top roboto-medium fw-medium  pt-2">
              Max Upload Size
            </div>
            <div className="col roboto-regular p-1">250 MB</div>
          </Tooltip>
        </div>
        </div>
      </div>
    </div>
  );
};

export default InnerModel;

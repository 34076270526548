export const qrcode_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-qr.png`;
export const silo_marketplace_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-marketplace.png`;  
export const silotalk_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-talk.png`;
export const storage_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-storage.png`;
export const streamdeck_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-streamdeck.png`;
export const support_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-support.png`;
export const tv_logo = `${process.env.REACT_APP_API_URL}logo/apps/tv-logo.png`;
export const Homelightlogo = `${process.env.REACT_APP_API_URL}logo/apps/home-logo.png`;
export const silogame = `${process.env.REACT_APP_API_URL}logo/apps/silo-games.png`;
export const store_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-store.png`;

export const img = `${process.env.REACT_APP_API_URL}logo/apps/background-image.png`;
export const calendar = `${process.env.REACT_APP_API_URL}logo/apps/silo-calender.png`;
export const mail_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-mail.png`;
export const community_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-community.png`;
export const assembler_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-assembler.png`;
export const pesonapost_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-post.png`;
export const pesonadigest_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-digest.png`;
export const pesonaos_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-os.png`;
export const pesonaradio_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-radio.png`;
export const erp_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-erp.png`;
export const suite_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-suite.png`;
export const constructor_logo = `${process.env.REACT_APP_API_URL}logo/apps/constructor-tool.png`;
export const canvas_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-canvas.png`;
export const map_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-maps.png`;
export const wallet_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-wallet.png`;
export const coin_exchange_logo = `${process.env.REACT_APP_API_URL}logo/apps/coin_exchange.png`;
export const merchant_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-merchant-white.png`;
export const merchant_logo_d = `${process.env.REACT_APP_API_URL}logo/apps/silo-merchant-dark.png`;
export const blockchain_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-blockchain.png`;
export const silo_bank = `${process.env.REACT_APP_API_URL}logo/apps/silo-bank.png`;
export const site_builder = `${process.env.REACT_APP_API_URL}logo/apps/site-builder.png`;
export const marketplace_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-marketplace.png`;
export const silo_viewer = `${process.env.REACT_APP_API_URL}logo/apps/silo-3d-viewer.png`;
export const silo_publisher = `${process.env.REACT_APP_API_URL}logo/apps/silo-publisher.png`;

export const d_plus_logo = require("./black_theme/plus_black.png");
export const d_home_logo = require("./black_theme/home_black2.png");
export const Blockchain_icon = require("./Blockchain (1).png");
export const Blockchain_icon_dark = require("./Blockchain (2).png");
export const d_calendar = require("./black_theme/calendar.png");
export const w_calendar = require("./white_theme/calender_white.png");
export const w_bell = require("./white_theme/bell_white.png");
export const d_bell = require("./black_theme/bell.png");
export const dark_calendar = require("./Dark Calendar.png");
// export const white_calendar = require("./Calendar.png");

// export const qrcode_logo = require("./qr_logos.png");    
// export const silo_marketplace_logo = require("./silo_marketplace_logo.png");
// export const silosecuredata_logo = require("./silosecuredata_logo.png");
// export const silotalk_logo = require("./silotalk_logo.png");
// export const storage_logo = require("./storage_logo.png");
// export const streamdeck_logo = require("./streamdeck_logo.png");
// export const support_logo = require("./support_logo.png");
// export const tv_logo = require("./tv_logo.png");
// export const homelogo = require("./homesilo.png");
// export const silogame = require("./silogames.png");
// export const store_apps = require("./store_apps.png");
// pov over images in footer for white mode

// export const pop_silogame = require("./white_theme/game_white.png");
// export const pop_qrcode_logo = require("./white_theme/qr_white.png");
// export const pop_silo_marketplace_logo = require("./white_theme/mktplace_white.png");
// export const pop_storage_logo = require("./white_theme/storage_white.png");
// export const pop_silosecure_data = require("./white_theme/securedata_white.png");
// export const pop_streamdeck_logo = require("./white_theme/streamdeck_white.png");
// export const pop_tv_logo = require("./white_theme/tv_white.png");
// export const pop_silotalk_logo = require("./white_theme/silotalk_regular.png");
// export const background_light = require("./white_theme/new_bg_white.png");
// export const searchpage_logo = require("./white_theme/silocloud_white.png");

// // Images for dark mode for header and searchpage

// export const d_apps_logo = require("./black_theme/app.png");
// export const d_searchpage_logo = require("./black_theme/silocloud_black.png");

// // Popover images for darkmode

// export const d_streamdeck_logo = require("./black_theme/streamdeck_black.png");
// export const d_tv_logo = require("./black_theme/tv_black.png");
// export const d_silo_talk = require("./black_theme/silotalk_black.png");
// export const d_game_logo = require("./black_theme/game_black.png");
// export const d_qr_logo = require("./black_theme/qr_black.png");
// export const d_marketplace_logo = require("./black_theme/mktplace.png");
// export const d_storage_logo = require("./black_theme/storage_black.png");
// export const d_secure_data = require("./black_theme/securedata_black.png");

// export const mikelogo_dark = require("./black_theme/mic_icon_black.png");
// export const d_store_logo = require("./black_theme/d_store_logo.png");
// // right bottom corner images

// export const d_silo_talk_app = require("./black_theme/silo_talk_app.png");
// export const d_silo_talk_video = require("./black_theme/silo_talk_video.png");
// export const d_silo_talk_audio = require("./black_theme/silo_talk_audio.png");
// export const d_silo_talk_end_call = require("./black_theme/silotalk_end_call.png");

// // export const Homelightlogo = require("./white_theme/home_white.png");
// export const plus = require("./white_theme/plus_white.png");
// // export const img = require("./white_theme/img_white.png");

// // Popover images for lightmode
// export const w_store_logo = require("./white_theme/w_store_logo.png");

//     // export const calender_apps = require("./calender_apps.png");
//     // export const mail_apps = require("./mail.png");
// // export const community_apps = require("./community.png");
// // export const assembler_apps = require("./assembler.png");
// // export const pesonapost_apps = require("./personapost.png");
// // export const pesonadigest_apps = require("./personadigest.png");
// // export const pesonaos_apps = require("./personaos.png");
// // export const pesonaradio_apps = require("./personaradio.png");
// // export const erp_logo = require("./erp.png");
// // export const suite_logo = require("./suite.png");
// // export const constructor_logo = require("./consructor.png");
// // export const canvas_logo = require("./canva.png");
// // export const map_logo = require("./map.png");
// // export const wallet_logo = require("./wallet.png");
// // export const coin_exchange_logo = require("./coin-exchange.png");
// // export const merchant_logo = require("./merchant.png");
// // export const blockchain_logo = require("./blockchain.png");
// export const silo_talk = require("./silo_talk.png");
// export const silogames = require("./silogames.png");
// export const silo_games = require("./silo_games.png");
// // export const silo_bank = require("./Silo_Bank.png");
// // export const site_builder = require("./site_builder.png");

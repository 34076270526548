import React, { useState, useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Collection.css";
import { useTheme } from "../../contexts/ThemeContext";
import { getCookie } from "../../utils/utils";
import toast, { Toaster } from "react-hot-toast";
import Nofound from "../../assets/Images/NoResultFound.png";
import { RxCross2 } from "react-icons/rx";
import { getCollection } from "../https/getCollection";
import { useQuery } from "@tanstack/react-query";

const Collectionpage = ({ queryClient }) => {
  const authToken = getCookie("authToken");
  const [isLoading, setIsLoading] = useState(true);
  const [models, setModels] = useState([]);
  const { theme } = useTheme();
  const toastRef = useRef(null); // Create a ref for the toast container

  // Function to handle clicks outside the toast
  const handleClickOutside = (event) => {
    if (toastRef.current && !toastRef.current.contains(event.target)) {
      toast.dismiss(); // Dismiss the toast if clicked outside
    }
  };

  const handleDelete = useCallback(
    (id) => {
      const formData = new FormData();
      formData.append("three_d_id", id);

      const confirmToast = toast(
        (t) => (
          <div ref={toastRef}>
            <div className="mb-2">
              Are you sure you want to delete this model?
            </div>
            <div>
              <button
                onClick={() => {
                  toast.dismiss(t.id);
                  const deletingToastId = toast.loading("Deleting model...");
                  axios({
                    method: "post",
                    url: "https://api.silocloud.io/api/v1/three-d/delete-threed-file",
                    headers: {
                      authToken: authToken,
                      "Content-Type": "multipart/form-data",
                    },
                    data: formData,
                  })
                    .then((response) => {
                      if (response.data.code === 200 && response.data.status) {
                        setModels((prevModels) =>
                          prevModels.filter((model) => model.id !== id)
                        );
                        toast.success("Model deleted successfully!", {
                          id: deletingToastId,
                        });
                      } else {
                        throw new Error("Model deletion failed");
                      }
                    })
                    .catch((error) => {
                      console.error(
                        "There was an error deleting the model!",
                        error
                      );
                      toast.error("Error deleting model. Please try again.", {
                        id: deletingToastId,
                      });
                    });
                }}
                className="btn btn-danger me-2"
              >
                Yes
              </button>
              <button
                onClick={() => toast.dismiss(t.id)}
                className="btn btn-secondary"
              >
                No
              </button>
            </div>
          </div>
        ),
        {
          duration: Infinity,
          position: "top-center",
        }
      );
    },
    [authToken, setModels]
  );

  // Effect for detecting clicks outside of the toast
  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const {
    data: collection,
    isLoading: collectionIsLoading,
    isRefetching: collectionIsRefetching,
    refetch: collectionRefetch,
  } = useQuery({
    queryKey: ["getThreeD"],
    queryFn: getCollection,
    gcTime: 0,
    staleTime: Infinity,
  });

  return (
    <div
      className="container-fluid p-0"
      style={{
        backgroundColor: theme === "dark" ? "#212529" : "#FFFFFF",
        color: theme === "dark" ? "#fff" : "#2A2B2E",
      }}
    >
      <div className="d-flex">
        <div
          className="flex-grow-1 content-container"
          style={{
            backgroundColor: theme === "dark" ? "#bac9d0e8" : "#FFFFFF",
            color: theme === "dark" ? "#fff" : "#2A2B2E",
          }}
        >
          <div className="col-12  px-1">
            <div className="d-flex justify-content-between align-items-center border-bottom my-2 px-2">
              <h3 className="fs-2 ">My Collections</h3>
            </div>

            <div
              className="py-1 px-2 overflow-y-auto overflow-x-hidden collection-sidebar "
              style={{
                height: "78.5vh",
              }}
            >
              <div className="row">
                {collectionIsLoading ? (
                  Array.from({ length: 12 }).map((_, index) => (
                    <div
                      key={index}
                      className="col-lg-3 col-sm-4 col-6 my-2 position-relative image-wrapper"
                    >
                      <div
                        height={170}
                        className={`shimmercollection2 rounded-2 mb-2 ${
                          theme === "dark" ? "shimmer2" : "shimmer1"
                        }`}
                      />
                      <div
                        className={`rounded-2 mb-2 ${
                          theme === "dark" ? "badgeeffect1" : "badgeeffect2"
                        }`}
                      />
                    </div>
                  ))
                ) : collection?.data?.data?.three_d &&
                  collection?.data?.data?.three_d.length > 0 ? (
                  collection.data.data.three_d.map((model) => {
                    const uploadDate = new Date(model.date);
                    const formattedDate = !isNaN(uploadDate)
                      ? uploadDate.toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })
                      : "Invalid date";

                    return (
                      <div
                        key={model.id}
                        className="col-lg-3 col-sm-4 col-6 my-3 position-relative image-wrapper"
                      >
                        <Link
                          to={`/model3/${encodeURIComponent(model.id)}`}
                          className="image-box2 fit-image2 d-flex justify-content-center rounded-2"
                        >
                          <img
                            src={model.thumbnail_path}
                            alt={model.file_name}
                            className="w-100 rounded-2 border getmodelheigth2"
                          />
                        </Link>
                        <button
                          onClick={() => handleDelete(model.id)}
                          className="btn position-absolute top-0 p-1 mt-1 end-0 delete-button"
                          style={{ zIndex: 1, marginRight: "30px" }}
                        >
                          <RxCross2 className="fs-3 fw-blod text-danger text-danger-hover" />
                        </button>
                        <div className="py-3">
                          <div className="textmodelupload ms-3">
                            {model.file_name}
                          </div>
                          <div className="d-flex justify-content-between gap-1 align-items-center">
                            <div className="textmodelupload3 ms-3">
                              {formattedDate}
                            </div>
                            <div className="textmodelupload2 me-3">
                              {model.file_size}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex  justify-content-center align-items-center ">
                    <div className="w-25 h-25 mt-5">
                      <img
                        src={Nofound}
                        className=""
                        alt="No models found"
                        style={{ overflow: "hidden", height: "150px" }}
                      />
                      <p className="text-center mt-4 roboto-medium">
                        No 3D Model Found. <br /> Add Some To Your Collection!
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </div>
  );
};

export default Collectionpage;
